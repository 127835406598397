<div class="inbreeding-form">
    <app-horse-field label="Anförälder" [(ngModel)]="selectedHorse" [required]="true"></app-horse-field>
    <mat-form-field>
        <mat-label>Mönster</mat-label>
        <mat-select required [(value)]="selectedPattern">
            <mat-option [value]="p" *ngFor="let p of patterns">
                <span *ngIf="p.length > 3">Alla mönster</span>
                <span *ngIf="p.length === 3">{{ p }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

import {Component, OnInit} from '@angular/core';
import {PedigreeService} from './pedigree.service';
import {Pedigree} from './pedigree';
import {PedigreeField} from './pedigreeField';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PedigreeProfile} from './pedigreeProfile';
import {Horse} from '../model/horse';
import {Column} from '../model/column';
import {switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-pedigree',
    templateUrl: './pedigree.component.html',
    styleUrls: ['./pedigree.component.css']
})
export class PedigreeComponent implements OnInit {

    columns: Column[] = [
        {
            identifier: 'ancestor',
            heading: 'Anförälder'
        },
        {identifier: 'occurrenceCount', heading: 'Förekomster'},
        {identifier: 'occurrenceCountX', heading: 'I X-position'},
        {identifier: 'pathCount', heading: 'Vägar'},
        {identifier: 'inbreedingDescription', heading: 'Beskrivning'}
    ];
    loading = false;
    pedigree: Pedigree;
    // TODO: set size from screen width
    size = 6;
    private sortColumn: string;
    private sortOrder = -1;


    constructor(private pedigreeService: PedigreeService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    colId(index: number, col: Column): string {
        return col.identifier;
    }

    displayedColumns(): string[] {
        return this.columns
            .filter(col => !col.hidden)
            .map(col => col.identifier);
    }

    styleNrToColor(styleNr: number): string {
        switch (styleNr) {
            case 1:
                return '#F0A3FF';
            case 2:
                return '#0075DC';
            case 3:
                return '#FFA8BB';
            case 4:
                return '#2BCE48';
            case 5:
                return '#FFCC99';
            case 6:
                return '#808080';
            case 7:
                return '#94FFB5';
            case 8:
                return '#FF0010';
            case 9:
                return '#9DCC00';
            case 10:
                return '#FFA405';
            case 11:
                return '#5EF1F2';
            case 12:
                return '#E0FF66';
            case 13:
                return '#FF5005';
            case 14:
                return '#8F7C00';
            case 15:
                return '#8FB0FF';

            // {"#000000","#FFFF00","#1CE6FF","#FF34FF","#FF4A46","#008941","#006FA6","#A30059","#FFDBE5","#7A4900","#0000A6","#63FFAC",
            // "#B79762","#004D43","#8FB0FF","#997D87","#5A0007","#809693","#FEFFE6","#1B4400","#4FC601","#3B5DFF","#4A3B53","#FF2F80",
            // "#61615A","#BA0900","#6B7900","#00C2A0","#FFAA92","#FF90C9","#B903AA","#D16100","#DDEFFF","#000035","#7B4F4B","#A1C299",
            // "#300018","#0AA6D8","#013349","#00846F","#372101","#FFB500","#C2FFED","#A079BF","#CC0744","#C0B9B2","#C2FF99","#001E09",
            // "#00489C","#6F0062","#0CBD66","#EEC3FF","#456D75","#B77B68","#7A87A1","#788D66","#885578","#FAD09F","#FF8A9A","#D157A0",
            // "#BEC459","#456648","#0086ED","#886F4C","#34362D","#B4A8BD","#00A6AA","#452C2C","#636375","#A3C8C9","#FF913F","#938A81",
            // "#575329","#00FECF","#B05B6F","#8CD0FF","#3B9700","#04F757","#C8A1A1","#1E6E00","#7900D7","#A77500","#6367A9","#A05837",
            // "#6B002C","#772600","#D790FF","#9B9700","#549E79","#FFF69F","#201625","#72418F","#BC23FF","#99ADC0","#3A2465","#922329",
            // "#5B4534","#FDE8DC","#404E55","#0089A3","#CB7E98","#A4E804","#324E72","#6A3A4C","#83AB58","#001C1E","#D1F7CE","#004B28",
            // "#C8D0F6","#A3A489","#806C66","#222800","#BF5650","#E83000","#66796D","#DA007C","#FF1A59","#8ADBB4","#1E0200","#5B4E51",
            // "#C895C5","#320033","#FF6832","#66E1D3","#CFCDAC","#D0AC94","#7ED379","#012C58","#7A7BFF","#D68E01","#353339","#78AFA1",
            // "#FEB2C6","#75797C","#837393","#943A4D","#B5F4FF","#D2DCD5","#9556BD","#6A714A","#001325","#02525F","#0AA3F7","#E98176",
            // "#DBD5DD","#5EBCD1","#3D4F44","#7E6405","#02684E","#962B75","#8D8546","#9695C5","#E773CE","#D86A78","#3E89BE","#CA834E",
            // "#518A87","#5B113C","#55813B","#E704C4","#00005F","#A97399","#4B8160","#59738A","#FF5DA7","#F7C9BF","#643127","#513A01",
            // "#6B94AA","#51A058","#A45B02","#1D1702","#E20027","#E7AB63","#4C6001","#9C6966","#64547B","#97979E","#006A66","#391406",
            // "#F4D749","#0045D2","#006C31","#DDB6D0","#7C6571","#9FB2A4","#00D891","#15A08A","#BC65E9","#FFFFFE","#C6DC99","#203B3C",
            // "#671190","#6B3A64","#F5E1FF","#FFA0F2","#CCAA35","#374527","#8BB400","#797868","#C6005A","#3B000A","#C86240","#29607C",
            // "#402334","#7D5A44","#CCB87C","#B88183","#AA5199","#B5D6C3","#A38469","#9F94F0","#A74571","#B894A6","#71BB8C","#00B433",
            // "#789EC9","#6D80BA","#953F00","#5EFF03","#E4FFFC","#1BE177","#BCB1E5","#76912F","#003109","#0060CD","#D20096","#895563",
            // "#29201D","#5B3213","#A76F42","#89412E","#1A3A2A","#494B5A","#A88C85","#F4ABAA","#A3F3AB","#00C6C8","#EA8B66","#958A9F",
            // "#BDC9D2","#9FA064","#BE4700","#658188","#83A485","#453C23","#47675D","#3A3F00","#061203","#DFFB71","#868E7E","#98D058",
            // "#6C8F7D","#D7BFC2","#3C3E6E","#D83D66","#2F5D9B","#6C5E46","#D25B88","#5B656C","#00B57F","#545C46","#866097","#365D25",
            // "#252F99","#00CCFF","#674E60","#FC009C","#92896B"}
        }
        return '#FFFFFF';   // TODO: should be transparent?
    }

    getRootHorse(): Horse {
        if (this.pedigree) {
            return this.pedigree.fields[0].horse;
        } else {
            return <Horse>{id: 0, name: ''};
        }
    }

    calculateRowSpan(field: PedigreeField): number {
        return Math.pow(2, this.size - field.position.length);
    }

    calculateGridRows(): number {
        return Math.pow(2, this.size);
    }

    ngOnInit() {
        this.route.queryParamMap.pipe(
            tap(paramMap => this.size = paramMap.get('size') ? Number(paramMap.get('size')) : this.size),
            tap(_ => this.loading = true),
            switchMap(paramMap => this.pedigreeService.getPedigree(this.createUrlParams(paramMap, this.size))),
            tap(_ => this.loading = false),
            // TODO: work with observable instead?
        ).subscribe(
            p => this.pedigree = p
            // TODO: proper error handling
        );
    }

    private reload(size: number) {
        this.router.navigate(['/pedigree'], {queryParams: this.createUrlParams(this.route.snapshot.queryParamMap, size)});
    }

    pedigreeFields(): PedigreeField[] {
        if (this.pedigree) {
            return this.pedigree.fields
                .slice(1)
                .filter(field => field.position.length <= this.size);
        } else {
            // TODO: return empty pedigree of size this.size
            return [];
        }
    }

    columnActive(col: Column): boolean {
        return this.sortColumn === col.identifier;
    }

    getSortOrder(): string {
        if (this.sortOrder < 0) {
            return 'DESC';
        } else {
            return 'ASC';
        }
    }

    sort(col: Column) {
        this.sortColumn = col.identifier;
        this.sortOrder = this.sortOrder * -1;
        this.pedigree.profiles.sort((a, b) => this.compare(col.identifier, a, b, this.sortOrder));
    }

    private compare(prop: string, a: PedigreeProfile, b: PedigreeProfile, order: number): number {
        if (a[prop] < b[prop]) {
            return -1 * order;
        }
        if (a[prop] > b[prop]) {
            return order;
        }
        // TODO: fix this - can lead to infinite recursion
        return this.compare('ancestor', a, b, 1);
    }

    updateSize(newSize: number) {
        this.reload(newSize);
        /*
        if (newSize <= this.loadedPedigreeSize()) {
            this.size = newSize;
        } else {
            this.reload(newSize);
        }*/
    }

    private loadedPedigreeSize(): number {
        return Math.log(this.pedigree.fields.length + 1) / Math.LN2 - 1;
    }

    onReplaceMainHorse(pedigreeField: PedigreeField) {
        this.router.navigate(['/pedigree'], {
            queryParams: {
                size: this.size,
                h: pedigreeField.horse.id
            }
        });
    }

    onReplaceAncestor(pedigreeField: PedigreeField) {
        const urlParams = this.pedigreeService.createTestMatingParams(this.pedigree, pedigreeField.position, pedigreeField.horse.id);
        // const urlParams = this.replaceInUrlParams(this.route.snapshot.queryParamMap, this.size, pedigreeField);
        this.router.navigate(['/pedigree'], {queryParams: urlParams});
    }

    private createUrlParams(inParamMap: ParamMap, size: number): any {
        const outParams: any = {size};
        inParamMap.keys
            .filter(k => this.isPedigreeParam(k))
            .forEach(k => outParams[k] = inParamMap.get(k));
        return outParams;
    }

    /*

private replaceInUrlParams(inParamMap: ParamMap, size: number, replacement: PedigreeField): any {
    const outParams: any = {size};
    if (replacement.position === 'h') {
        outParams['h'] = replacement.horse.id;
        return outParams;
    }
    inParamMap.keys
        .filter(k => this.isPedigreeParam(k))
        .filter(k => k !== 'h')
        .filter(k => !replacement.position.startsWith(k))
        .forEach(k => outParams[k] = inParamMap.get(k));
    outParams[replacement.position] = replacement.horse.id;
    const replacementMatePosition = this.findReplacementMatePosition(replacement.position);
    const replacementMate = this.findReplacementMate(replacementMatePosition);
    if (replacementMate) {
        outParams[replacementMatePosition] = replacementMate;
    }
    return outParams;
}

private findReplacementMate(replacementMatePosition: string): number {
    const replacementMateField = this.pedigree.fields
        .filter(field => field.horse)
        .filter(field => replacementMatePosition.startsWith(field.position))
        // .filter(field => !replacementMatePosition.startsWith(field.position))
        .sort((a, b) => b.position.length - a.position.length);
    return replacementMateField[0].horse.id;
}

private findReplacementMatePosition(replacementPosition: string): string {
    return [...replacementPosition]
        .map((c: AncestorType, i) => i < replacementPosition.length - 1 ? c : this.otherThan(c))
        .join('');
}*/


    private isPedigreeParam(paramName: string): boolean {
        return paramName === 'h' || /[fm]+/g.test(paramName);
    }

    gridTemplateColumns(): string {
        return `repeat(${this.size}, 1fr)`;
    }

    gridTemplateRows(): string {
        return `repeat(${Math.pow(2, this.size)}, 12px)`;
    }

    calculateGridRow(position: string) {
        /*
        f -> 1 / 4
        m -> 5 / 8
        ff -> 1 / 2
        fm -> 3 / 4
        mf -> 5 / 6
        mm -> 7 / 8
        fff -> 1 / 1
        ffm -> 2 / 2
        fmf -> 3 / 3
        fmm -> 4 / 4
        mff -> 5 / 5
        mfm -> 6 / 6
        mmf -> 7 / 7
        mmm -> 8 / 8
         */
        const rowSpan = Math.pow(2, this.size - position.length);
        const binary = position
            .split('f').join('0')
            .split('m').join('1');
        const row = parseInt(binary, 2);
        const gridRows = (rowSpan === 1) ? `${row + 1}` : `${row * rowSpan + 1} / ${(row + 1) * rowSpan + 1}`;
        return gridRows;

    }


}

import {Component, OnInit} from '@angular/core';
import {DuelService} from '../duel/duel.service';
import {DuelRecord} from '../model/duelrecord';

@Component({
  selector: 'app-poc',
  templateUrl: './poc.component.html',
  styleUrls: ['./poc.component.css']
})
export class PocComponent implements OnInit {

  recordEntries: DuelRecord[] = [];


  constructor(private duelService: DuelService) { }

  ngOnInit(): void {
    this.duelService.findDuelRecord(5209998, 588796).subscribe(duelRecord => {
      this.recordEntries = duelRecord;
    });
  }

}

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Horse} from '../model/horse';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {SearchService} from './search.service';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {OrderSpec, SearchSpec} from '../production-record/search-spec';
import {emptyResult, SearchResult, SearchStats} from '../model/search-result';
import {HttpErrorResponse} from '@angular/common/http';

export type DataSourceState = 'INITIAL' | 'LOADING' | 'COMPLETE' | 'TIMEOUT' | 'OTHER_ERROR';

export class SearchDataSource extends DataSource<Horse> {

    private resultsSubject = new BehaviorSubject<SearchResult>(emptyResult);
    private stateSubject = new BehaviorSubject<DataSourceState>('INITIAL');

    public state$ = this.stateSubject.asObservable();

    public statSe$ = this.resultsSubject.asObservable()
            .pipe(
                map(result => result.statSe)
            );


    private searchSpec: SearchSpec = {
        criteria: [],
        offset: 0,
        orderSpec: null
    };


    constructor(private searchService: SearchService) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<Horse[] | ReadonlyArray<Horse>> {
        return this.resultsSubject
            .pipe(
                map(result => result.horses)
            );
    }

    resultCount(): Observable<number> {
        return this.resultsSubject
            .pipe(
                map(result => result.total)
            );
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.resultsSubject.complete();
        this.stateSubject.complete();
    }

    page(newOffset: number) {
        this.searchSpec.offset = newOffset;
        this.doSearch();
    }

    reorder(orderSpec: OrderSpec) {
        this.searchSpec.orderSpec = orderSpec;
        this.searchSpec.offset = 0;
        this.doSearch();
    }

    newSearch(searchSpec: SearchSpec) {
        this.searchSpec = searchSpec;
        this.doSearch();
    }

    private doSearch() {
        this.stateSubject.next('LOADING');
        this.searchService.search(this.searchSpec).subscribe(
            results => {
                this.stateSubject.next('COMPLETE');
                this.resultsSubject.next(results);
            },
            error => {
                console.log('Fel', error);
                if (error.status === 504) {
                    this.stateSubject.next('TIMEOUT');
                } else {
                    this.stateSubject.next('OTHER_ERROR');
                }
            });
    }

    reset() {
        // this.stateSubject.next('INITIAL');
    }
}

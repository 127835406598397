<mat-form-field [hintLabel]="hintLabel">
  <mat-chip-list #chipList [selectable]="true" [multiple]="true">
    <mat-chip
      *ngFor="let selectedHorse of selectedHorses"
      [color]="color"
      [removable]="true"
      (removed)="remove(selectedHorse)"
      selectable
      selected>
      <span>{{selectedHorse.name }} ({{ selectedHorse.nationality }}) {{ selectedHorse.birthYear }}</span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input #horseInput
           [placeholder]="label"
           [formControl]="control"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           (keyup.enter)="forceSearch()" (blur)="resetInput()">
  </mat-chip-list>
</mat-form-field>

<mat-autocomplete class="completions" #auto="matAutocomplete" [displayWith]="displayFunction">
  <mat-option class="completions text-md" *ngFor="let horse of filteredHorses | async" [value]="horse"
              (onSelectionChange)="onHorseSelected($event, horse)">
    {{ horse.name }} <span class="text-md">({{ horse.nationality }}) {{ horse.gender }} {{ horse.birthYear }}</span>
  </mat-option>
</mat-autocomplete>

import {Component} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: 'app-help',
  templateUrl: 'help.component.html',
  styleUrls: ['help-section.component.css']
})
export class HelpDialog {

  constructor(public dialogRef: MatDialogRef<HelpDialog>) {
  }

  onClose() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}

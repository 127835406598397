import {Component, OnInit} from '@angular/core';
import {SearchFormComponent} from '../search-form-component';

@Component({
    selector: 'app-year-criterion',
    templateUrl: './year-criterion.component.html',
    styleUrls: ['./year-criterion.component.css']
})
export class YearCriterionComponent implements SearchFormComponent, OnInit {

    private readonly thisYear = 2020;

    options = {
        autoHideLimitLabels: true,
        floor: 1,
        ceil: 219,
        logScale: true,
        translate: (value: number): string => {
            return (this.thisYear - value).toString();
        },
        rightToLeft: true
    };
    minAge = 3;
    maxAge = 15;

    constructor() {
    }

    ngOnInit() {
        this.options = {
            autoHideLimitLabels: true,
            floor: 1,
            ceil: 219,
            logScale: true,
            translate: (value: number): string => {
                return (2020 - value).toString();
            },
            rightToLeft: true
        };
    }

    validationError(): string {
        return null;
    }

    initModel(model: any) {
        this.minAge = 2020 - model.maxYear;
        this.maxAge = 2020 - model.minYear;
    }

    retrieveExtra() {
        return null;
    }

    retrieveModel() {
        return {
            minYear: 2020 - this.maxAge,
            maxYear: 2020 - this.minAge
        };
    }

}

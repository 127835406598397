import {Component, forwardRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {HorseService} from './horse.service';
import {Horse} from '../model/horse';
import {MatOptionSelectionChange} from '@angular/material/core';
import {AbstractHorseFieldComponent} from './abstract-horse-field.component';

@Component({
    selector: 'app-horse-field',
    templateUrl: './horse-field.component.html',
    styleUrls: ['./horse-field.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => HorseFieldComponent),
        multi: true
    }]
})
export class HorseFieldComponent extends AbstractHorseFieldComponent implements OnInit, OnDestroy {

    @Input() required = false;

    selectedHorse: Horse;

    onChange = (selection: Horse) => {
    }

    constructor(protected horseService: HorseService) {
        super(horseService);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onHorseSelected(change: MatOptionSelectionChange, horse: Horse): void {
        this.horseSelected(horse);
    }

    horseSelected(horse: Horse): void {
        this.selectedHorse = horse;
        this.resetInput();
        this.selected.emit(horse);
        this.onChange(this.selectedHorse);
    }

    resetInput() {
        this.horseInput.nativeElement.value = '';
        this.control.setValue(null);
    }

    reset() {
        this.selectedHorse = null;
        this.selected.emit(null);
        this.onChange(null);
    }

    registerOnChange(fn: (selection: Horse) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(selection: Horse): void {
        this.selectedHorse = selection;
    }

}

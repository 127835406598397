<div *ngIf="!searchOpen" class="pedigree-field">
    <app-horse-display *ngIf="pedigreeField.horse?.id" [size]="horseDisplaySize"
                       [horse]="pedigreeField.horse"></app-horse-display>
    <span *ngIf="pedigreeField.position === 'h' && !pedigreeField.horse?.id"
          class="test-mating">&nbsp;Testparning</span>
    <button mat-icon-button *ngIf="size > 0" (click)="openSearch()">
        <span class="material-icons-outlined replace-icon">change_circle</span>
    </button>
</div>
<app-horse-search *ngIf="searchOpen" #horseSearch label="Sök" (selected)="onSelectedHorse($event)"></app-horse-search>

import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatOptionSelectionChange} from '@angular/material/core';
import {SearchFilter} from '../searchFilter';
import {Horse} from '../../model/horse';
import {HorseService} from '../horse.service';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-multiple-horse-field',
    templateUrl: 'multiple-horse-field.component.html',
    styleUrls: ['./../horse-field.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MulitpleHorseFieldComponent),
        multi: true
    }]

})
export class MulitpleHorseFieldComponent implements OnDestroy, OnInit, ControlValueAccessor {

    @Input() color: string;

    @Input() hintLabel: string;

    @Input() label: string;

    @Input() searchFilter: SearchFilter;

    @Output() selected = new EventEmitter<Horse>();

    @ViewChild(MatAutocompleteTrigger, {static: true}) trigger: MatAutocompleteTrigger;
    @ViewChild('horseInput', {static: true}) horseInput: any;

    filteredHorses: Observable<Horse[]>;

    control: FormControl = new FormControl();

    selectedHorses: Horse[] = [];

    private subscription: Subscription;

    onChange = (selection: Horse[]) => {};

    constructor(private horseService: HorseService) {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.subscription = this.control.valueChanges
            .pipe(debounceTime(250))
            .subscribe(searchString => {
                if (searchString && searchString.length > 1) {
                    this.filteredHorses = this.horseService.searchHorse(searchString, this.searchFilter);
                }
            });
    }

    forceSearch() {
        this.horseService.searchHorse(this.control.value, this.searchFilter).subscribe(results => {
            if (results.length === 1) {
                this.horseSelected(results[0]);
                this.trigger.closePanel();
            }
        });
    }


    displayFunction(horse: Horse): string {
        if (horse) {
            return horse.name;
        }
    }

    onHorseSelected(change: MatOptionSelectionChange, horse: Horse): void {
        this.horseSelected(horse);
    }

    horseSelected(horse: Horse): void {
        this.selectedHorses.push(horse);
        this.resetInput();
        this.selected.emit(horse);
        this.onChange(this.selectedHorses);

    }

    resetInput() {
        this.horseInput.nativeElement.value = '';
        this.control.setValue(null);
    }

    remove(selectedHorse) {
        const index = this.selectedHorses.indexOf(selectedHorse);

        if (index >= 0) {
            this.selectedHorses.splice(index, 1);
        }
        this.onChange(this.selectedHorses);
    }

    registerOnChange(fn: (selection: Horse[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(selection: Horse[]): void {
        this.selectedHorses = selection;
    }

}

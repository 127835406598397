import {Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {NumericRange} from '../model/numericRange';
import {Options} from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderComponent),
    multi: true
  }]
})
export class SliderComponent implements OnInit, ControlValueAccessor {

  @Input() ceil: number;
  @Input() floor: number;
  @Input() label: string;
  @Input() logScale = false;
  @Input() step = 1;
  @Input() suffix: string;

  @Input() range: NumericRange;
  options: Options;
  onChange = (range: NumericRange) => {};
  onTouched = () => {};

  constructor(private decimalPipe: DecimalPipe) { }

  ngOnInit() {
    this.range = {
      min: this.floor,
      max: this.ceil
    };
    this.options = <any>{
      ceil: this.ceil,
      floor: this.floor,
      logScale: this.logScale,
      step: this.step,
      translate: (value: number): string => {
        // TODO: locale
        return this.decimalPipe.transform(value) + this.suffix;
      }
    };

  }

  changed() {
    this.onChange(this.range);
  }


  writeValue(newRange: NumericRange): void {
    // this.oldRange = this.range;
    this.range = newRange;
  }

  registerOnChange(fn: (newRange: NumericRange) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}

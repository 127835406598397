import {Component} from '@angular/core';
import {Horse} from '../model/horse';
import {Duel} from '../model/duel';
import {SearchFilter} from '../horse-field/searchFilter';
import {Router} from '@angular/router';
import {HelpDialog} from '../help/help.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-duel-form',
  templateUrl: './duel-form.component.html',
  styleUrls: ['./duel-form.component.css']
})
export class DuelFormComponent {

  duels: Duel[] = [];
  sire1: Horse;
  sire2: Horse;

  readonly searchFilter: SearchFilter = {
    gender: 'MALE',
    // hasOffspring: true,
    minYear: 1950};

  constructor(public dialog: MatDialog, private router: Router) {
  }

  onSelected(sire: Horse) {
    if (sire) {
      this.router.navigate(['duels', sire.id]);
    }
  }

  onSelected1(event: Horse): void {
    this.sire1 = event;
    this.navigateIfComplete();
  }

  onSelected2(event: Horse): void {
    this.sire2 = event;
    this.navigateIfComplete();
  }

  openDialog(): void {
    this.dialog.open(HelpDialog);
  }


  private navigateIfComplete() {
    if (this.sire1 && this.sire2) {
      this.router.navigate(['duels', this.sire1.id, this.sire2.id]);
    }
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HttpClientModule} from '@angular/common/http';
import {HorseFieldComponent} from './horse-field/horse-field.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DuelComponent} from './duel/duel.component';
import {BreedersGuideComponent} from './breeders-guide/breeders-guide.component';
import {DuelRecordComponent} from './duel/duel-record.component';
import {PocComponent} from './poc/poc.component';
import {DuelFormComponent} from './duel/duel-form.component';
import {RouterModule, Routes} from '@angular/router';
import {HelpDialog} from './help/help.component';
import {HelpSectionComponent} from './help/help-section.component';
import {MulitpleHorseFieldComponent} from './horse-field/multiple/multiple-horse-field.component';
import {SliderComponent} from './slider/slider.component';
import {DecimalPipe} from '@angular/common';
import {ColumnSelectorComponent} from './column-selector/column-selector.component';
import {StartComponent} from './start/start.component';
import {Paginator} from './breeders-guide/paginator';
import {BreedersGuideTableComponent} from './breeders-guide/breeders-guide-table/breeders-guide-table.component';
import {CopyrightDialogComponent} from './copyright-dialog/copyright-dialog.component';
import {NewsitemComponent} from './newsitem/newsitem.component';
import {NewsItemDetailsComponent} from './news-item-details/news-item-details.component';
import {AdvancedSearchComponent} from './advanced-search/advanced-search.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import {GenderCriterionComponent} from './advanced-search/gender-criterion/gender-criterion.component';
import {SearchCriterionFormDirective} from './advanced-search/search-criterion-form-directive';
import { SearchCriterionFormDialogComponent } from './advanced-search/search-criterion-form-dialog/search-criterion-form-dialog.component';
import { YearCriterionComponent } from './advanced-search/year-criterion/year-criterion.component';
import { AncestorPositionCriterionComponent } from './advanced-search/ancestor-position-criterion/ancestor-position-criterion.component';
import { ProductionRecordComponent } from './production-record/production-record.component';
import { NationalityCriterionComponent } from './advanced-search/nationality-criterion/nationality-criterion.component';
import { InbreedingCriterionComponent } from './advanced-search/inbreeding-criterion/inbreeding-criterion.component';
import { BreedCriterionComponent } from './advanced-search/breed-criterion/breed-criterion.component';
import { BtbComponent } from './btb/btb.component';
import {PedigreeComponent} from './pedigree/pedigree.component';
import {HorseDisplayComponent} from './shared/horse-display/horse-display.component';
import {SizeSelectorComponent} from './pedigree/size-selector/size-selector.component';
import {PedigreeService} from './pedigree/pedigree.service';
import { PedigreeFieldComponent } from './pedigree/pedigree-field/pedigree-field.component';
import { HorseSearchComponent } from './horse-field/horse-search/horse-search.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';

const appRoutes: Routes = [
    {path: '', component: StartComponent},
    {path: 'duels', component: DuelFormComponent},
    {path: 'duels/:sire1', component: DuelComponent},
    {path: 'duels/:sire1/:sire2', component: DuelComponent},
    {path: 'breeders-guide', component: BreedersGuideComponent},
    {path: 'news/:newsItemId', component: NewsItemDetailsComponent},
    {path: 'advanced-search', component: AdvancedSearchComponent},
    {path: 'btb', component: BtbComponent },
    {path: 'pedigree', component: PedigreeComponent},
    {path: 'record', component: ProductionRecordComponent},
];

@NgModule({
    declarations: [
        AppComponent,
        BreedersGuideComponent,
        ColumnSelectorComponent,
        DuelComponent,
        DuelFormComponent,
        DuelRecordComponent,
        HelpDialog,
        HelpSectionComponent,
        HorseDisplayComponent,
        HorseFieldComponent,
        MulitpleHorseFieldComponent,
        PedigreeComponent,
        PocComponent,
        SizeSelectorComponent,
        SliderComponent,
        StartComponent,
        BreedersGuideTableComponent,
        CopyrightDialogComponent,
        NewsitemComponent,
        NewsItemDetailsComponent,
        AdvancedSearchComponent,
        GenderCriterionComponent,
        SearchCriterionFormDirective,
        SearchCriterionFormDialogComponent,
        YearCriterionComponent,
        AncestorPositionCriterionComponent,
        ProductionRecordComponent,
        NationalityCriterionComponent,
        InbreedingCriterionComponent,
        BreedCriterionComponent,
        BtbComponent,
        PedigreeFieldComponent,
        HorseSearchComponent
    ],
    entryComponents: [
        AncestorPositionCriterionComponent,
        BreedCriterionComponent,
        ColumnSelectorComponent,
        CopyrightDialogComponent,
        HelpDialog,
        GenderCriterionComponent,
        InbreedingCriterionComponent,
        NationalityCriterionComponent,
        SearchCriterionFormDialogComponent,
        YearCriterionComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatStepperModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxSliderModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes
// { enableTracing: true } // <-- debugging purposes only
, { relativeLinkResolution: 'legacy' }),
        MatTreeModule,
        MatSelectModule,
        MatExpansionModule,
        MatMenuModule,
        MatButtonToggleModule
    ],
    providers: [
        DecimalPipe,
        {provide: MatPaginatorIntl, useClass: Paginator},
        PedigreeService
        // {
        //   provide: LOCALE_ID,
        //   useValue: 'sv'
        // }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="copyright-dialog"></div>
<button mat-icon-button (click)="onClose()" class="close-button"><mat-icon>close</mat-icon></button>
<h2 mat-dialog-title>Viktig information om upphovsrättsskyddat material på Blodbanken</h2>
<div mat-dialog-content>
    <ul>
        <li>Blodbanken innehåller upphovsrättsligt/databasrättsligt skyddat material tillhandahållet
            enligt samarbetsavtal med flera olika leverantörer.
        </li>
        <li>Den publicerade hästsportinformationen får endast användas till icke-kommersiella syften.</li>
        <li>All form av kopiering (inklusive men inte begränsat till användning av automatiserade verktyg
            eller mjukvara som extraherar hästsportinformation) av hela eller delar av
            publicerad hästsportinformation är otillåten.
        </li>
    </ul>

    <h3 mat-dialog-title>Dataleverantörer</h3>
    <ul>
        <li><a href="https://www.trav.dk">Dansk Travsports Centralforbund (DTC)</a></li>
        <li><a href="http://www.travsport.no/">Det Norske Travselskap (DNT)</a></li>
        <li><a href="https://www.hvtonline.de/">Hauptverband für Traberzucht e.V. (HVT)</a></li>
        <li><a href="http://www.qhurth.com/">Qhurth (Kurt Anderssons hemsida)</a></li>
        <li><a href="http://www.hippos.fi/">Suomen Hippos ry</a></li>
        <li><a href="https://www.travsport.se/">Svensk Travsport (ST)</a></li>
    </ul>

    <h3 mat-dialog-title>Information som sparas i din webbläsare</h3>
    <p>Blodbanken sparar information i din webbläsare endast i syfte att behålla användarinställningar.
        Inga data delas med andra webbplatser (inga tredjeparts-kakor). </p>

    <h3 mat-dialog-title>Tillskrivning</h3>
        <p>Fotot på förstasidan av
            <a href="https://unsplash.com/ja/@toft_pilgaard?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Louise Pilgaard</a>
            på <a href="https://unsplash.com/photos/SBt7hj2QX7M?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        </p>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onAccept()">Jag har läst och förstått och godkänner</button>
</div>

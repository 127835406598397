import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SearchParams} from './breeders-guide/searchParams';
import {StallionResult} from './model/stallionResult';
import {ConformationProperty} from './breeders-guide/conformationProperty';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class StallionService {

  constructor(private config: ConfigService, private httpClient: HttpClient) {

  }

  findStallions(searchParams: SearchParams): Observable<StallionResult[]> {
    const temp: any = searchParams;
    return this.httpClient.get<StallionResult[]>(this.config.getBackEndHost() + '/stallions', { params: temp });
  }

  getConformationDeviations(): Observable<String[]> {
    return this.httpClient.get<String[]>(this.config.getBackEndHost() + '/conformation/deviations');
  }

  getConformationProperties(): Observable<ConformationProperty[]> {
    return this.httpClient.get<ConformationProperty[]>(this.config.getBackEndHost() + '/conformation/properties');
  }
}

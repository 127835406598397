import {Component, Input, OnInit} from "@angular/core";
import {DuelService} from "./duel.service";
import {DuelRecord} from "../model/duelrecord";
import {Duel} from "../model/duel";
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-duel-record',
  templateUrl: './duel-record.component.html',
  styleUrls: ['./duel-record.component.css']
})
export class DuelRecordComponent implements OnInit {

  @Input() duel: Duel;

  loaded = false;

  recordEntries: DuelRecord[] = [];

  constructor(private duelService: DuelService) { }

  ngOnInit(): void {
    this.duelService.findDuelRecord(this.duel.id1, this.duel.id2).pipe(take(1)).subscribe(duelRecord => {
      this.recordEntries = duelRecord;
      this.loaded = true;
    });
  }

  winner1(record: DuelRecord): boolean {
    return record.foal1.brix > record.foal2.brix;
  }

  winner2(record: DuelRecord): boolean {
    return record.foal2.brix > record.foal1.brix;
  }

  winnerDesc(record: DuelRecord): string {
    if (record.foal1.brix > record.foal2.brix) {
      return 'Seger för ' + this.duel.sire1;
    } else if (record.foal1.brix < record.foal2.brix) {
      return 'Seger för ' + this.duel.sire2;
    } else {
      return 'Oavgjort';
    }
  }


}

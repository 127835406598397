import {Component, Input, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormBuilder} from '@angular/forms';
import {Column} from '../model/column';
@Component({
  selector: 'app-column-selector',
  styleUrls: ['./column-selector.component.css'],
  templateUrl: './column-selector.component.html'
})
export class ColumnSelectorComponent {

  columns: Column[];

  visible: boolean[] = [];

  formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ColumnSelectorComponent>) {
    this.columns = data.cols;
    for (let i = 0; i < this.columns.length; i++) {
      this.visible[i] = !this.columns[i].hidden;
    }
  }

  all(val: boolean) {
    this.visible = this.visible.map(v => v = val);
  }

  onCancel() {
    if (this.dialogRef) {
      this.dialogRef.close(this.columns);
    }
  }

  onOk() {
    if (this.dialogRef) {
      this.dialogRef.close(this.getUpdatedColumns());
    }
  }

  // private findVisibleColumns(): string[] {
  //   const found: string[] = [];
  //   for (let i = 0; i <= this.columns.length; i++) {
  //     if (this.visible[i]) {
  //       found.push(this.columns[i].identifier);
  //     }
  //   }
  //   return found;
  // }

  private getUpdatedColumns() {
    return this.columns.map((col, i) => {
      col.hidden = !this.visible[i];
      return col;
    });
  }


}

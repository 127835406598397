<div class="ancestor-position-section">
    <app-horse-field label="Anförälder" [(ngModel)]="selectedHorse" (selected)="onSelected($event)"
                    [required]="true"></app-horse-field>
    <mat-accordion>
        <mat-expansion-panel [(expanded)]="exactExpanded">
            <mat-expansion-panel-header>Exakt position</mat-expansion-panel-header>
            <mat-form-field>
                <mat-label>Position</mat-label>
                <mat-select required [(value)]="position">
                    <mat-option *ngFor="let p of positions" [value]="p.id">{{ p.text }}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel [(expanded)]="generalExpanded">
            <mat-expansion-panel-header>Generellt</mat-expansion-panel-header>
            <mat-form-field>
                <mat-label>Del av stamtavlan</mat-label>
                <mat-select required [(value)]="pedigreeSection">
                    <mat-option *ngFor="let s of sections" [value]="s.id">{{ s.text }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <label>Djup</label>
                <ngx-slider [options]="options" [(value)]="minDepth" [(highValue)]="maxDepth"></ngx-slider>
                <div class="mat-hint mat-small">Inom {{ minDepth }} till {{ maxDepth }} generationer</div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</div>


import {Horse} from './horse';

export interface SearchStats {
    readonly count: number;
    readonly minYear: number;
    readonly maxYear: number;
    readonly dev: number;
}

export interface SearchResult {
    readonly total: number;
    readonly statSe: SearchStats;
    readonly horses: Horse[];
}

export const emptyResult: SearchResult = {total: 0, statSe: null, horses: []};

<mat-form-field>
  <mat-label>{{ label }} <span *ngIf="required">*</span></mat-label>
  <mat-chip-list #chipList [multiple]="false">
    <mat-chip
      *ngIf="selectedHorse"
      [removable]="true"
      (removed)="reset()"
      [selectable]="false">
      <span>{{selectedHorse.name }} ({{ selectedHorse.nationality }}) {{ selectedHorse.birthYear }}</span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input matInput
           #horseInput
           *ngIf="!selectedHorse"
           [placeholder]="label"
           [formControl]="control"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           (keyup.enter)="forceSearch()" (blur)="resetInput()">
  </mat-chip-list>
</mat-form-field>

<mat-autocomplete class="completions" #auto="matAutocomplete" [displayWith]="displayFunction">
  <mat-option class="completions text-md" *ngFor="let horse of filteredHorses | async" [value]="horse"
              (onSelectionChange)="onHorseSelected($event, horse)">
    {{ horse.name }} <span class="text-md">({{ horse.nationality }}) {{ horse.gender }} {{ horse.birthYear }}</span>
  </mat-option>
</mat-autocomplete>

<mat-dialog-content>
  <div class="mat-typography" *ngFor="let col of columns; index as i">
    <mat-checkbox [(ngModel)]="visible[i]" [matTooltip]="col.description">
        {{ col.heading }}
    </mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="all(true)">Välj alla</button>
  <button mat-button (click)="all(false)">Välj ingen</button>
</mat-dialog-actions>
<mat-dialog-actions>
  <button mat-raised-button (click)="onOk()">OK</button>
  <button mat-raised-button (click)="onCancel()">Avbryt</button>
</mat-dialog-actions>

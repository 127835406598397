import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NewsItem} from './model/newsItem';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  getNews(): Observable<NewsItem[]> {
    return this.http.get<NewsItem[]>('http://localhost:1337/bbnews');
  }

  getNewsItem(id: number): Observable<NewsItem> {
    return this.http.get<NewsItem>('http://localhost:1337/bbnews/' + id);
  }
}

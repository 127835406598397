<input matInput
       #horseInput
       class="search-field"
       [formControl]="control"
       [placeholder]="label"
       [matAutocomplete]="auto"
       (keyup.enter)="forceSearch()"
       (keyup.escape)="abortSearch()"
       (blur)="abortSearch()">
<mat-autocomplete class="completions" #auto="matAutocomplete" [displayWith]="displayFunction">
    <mat-option class="completions text-md" *ngFor="let horse of filteredHorses | async" [value]="horse"
                (onSelectionChange)="horseSelected(horse)">
        {{ horse.name }} <span class="text-md">({{ horse.nationality }}) {{ horse.gender }} {{ horse.birthYear }}</span>
    </mat-option>
</mat-autocomplete>


<router-outlet></router-outlet>

<mat-spinner *ngIf="loading" class="load-indicator"></mat-spinner>

<div style="margin-right: 1px" *ngIf="!loading">
    <div class="pedigree-top">
        <app-pedigree-field
                style="flex: 3 0 auto"
                [pedigreeField]="{ horse: getRootHorse(), position: 'h', styleNr: 0 }"
                size="2"
                (replacement)="onReplaceMainHorse($event)"
        ></app-pedigree-field>
        <div class="size-selector-header">Storlek&nbsp;</div>
        <app-size-selector style="flex: 0 0 auto" [size]="size" (change)="updateSize($event)"></app-size-selector>
    </div>

    <div class="pedigree"
         [style.grid-template-columns]="gridTemplateColumns()"
         [style.grid-template-rows]="gridTemplateRows()"
    >
        <div class = "pedigree-field-wrapper" *ngFor="let field of pedigreeFields(); let i = index"
             [style.background]="styleNrToColor(field.styleNr)"
             [style.grid-column]="field.position.length"
             [style.grid-row]="calculateGridRow(field.position)">
            <!-- TODO: use same popup for all? -->
            <app-pedigree-field [size]="size - field.position.length"
                                [pedigreeField]="field" (replacement)="onReplaceAncestor($event)"></app-pedigree-field>
        </div>
    </div>

    <div class="pedigree-info">
        <div class="pedigree-info-panel">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Tillgänglig information
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="text-md">
                    <div class="info-heading push-left" i18n>Stamtavlans minimidjup</div>
                    <div class="info-data">{{ pedigree?.minDepth }}</div>
                    <div class="info-heading push-left" i18n>Stamtavlans maximidjup</div>
                    <div class="info-data">{{ pedigree?.maxDepth }}</div>
                    <div class="info-heading push-left" i18n>Pedigree Completeness Index</div>
                    <div class="info-data">{{ pedigree?.pec }}</div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="pedigree-info-panel">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Generationsintervall
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>

        </div>
        <div class="pedigree-info-panel">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Raser
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </div>
        <div class="pedigree-info-panel">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Inavel
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </div>
        <div class="pedigree-info-panel">

            <mat-expansion-panel class="pedigree-info-panel" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Inavelsinslag
                    </mat-panel-title>
                </mat-expansion-panel-header>


                <!--<td-data-table
                  #dataTable
                  [data]="pedigree?.profiles"
                  [columns]="columns"
                  [selectable]="false"
                  [clickable]="true"
                  [multiple]="false"
                  [sortable]="true"
                  (sortChange)="sort($event)">
                  <ng-template tdDataTableTemplate="ancestor" let-value="value" let-row="row" let-column="column">
                    <div layout="row" [class]="styleNrToColor(row.styleNr)">
                      <span flex>{{value}}</span>
                    </div>
                  </ng-template>
                  <ng-template tdDataTableTemplate="occurrenceCount" let-value="value" let-row="row" let-column="column">
                    <div layout="row" [class]="styleNrToColor(row.styleNr)">
                      <span flex>{{value}}</span>
                    </div>
                  </ng-template>
                </td-data-table>-->

                <table mat-table [dataSource]="pedigree?.profiles">
                    <ng-container *ngFor="let col of columns trackBy colId" [matColumnDef]="col.identifier">
                        <th mat-header-cell *matHeaderCellDef [ngClass]="col.css" [matTooltip]="col.description">
                            {{col.heading}}
                        </th>

                        <td mat-cell *matCellDef="let profile" [style.background]="styleNrToColor(profile.styleNr)">
                            {{ profile[col.identifier] }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns()">

                </table>
            </mat-expansion-panel>
        </div>
    </div>
</div>

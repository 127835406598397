import {Component, AfterViewInit, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CopyrightDialogComponent} from '../copyright-dialog/copyright-dialog.component';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {NewsItem} from '../model/newsItem';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {NewsService} from '../news.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.css']
})
export class StartComponent implements AfterViewInit, OnInit {

    readonly TERMS_ARE_APPROVED = '#TERMS_ARE_APPROVED#';
    newsItems: Observable<NewsItem[]> = of([]);
    imgLoaded = false;

    constructor(public dialog: MatDialog, private localStorage: LocalStorage, private newsService: NewsService) {
    }

    ngOnInit(): void {
        // this.newsItems = this.newsService.getNews();
    }

    ngAfterViewInit() {
        // getItem<string>(this.HIDDEN_COLS_KEY, {schema: {type: 'string'}}
        this.localStorage.getItem<string>(this.TERMS_ARE_APPROVED, {schema: {type: 'string'}}).pipe(take(1)).subscribe(approved => {
            if (!approved) {
                Promise.resolve().then(() => {
                    this.dialog
                        .open(CopyrightDialogComponent, {width: '800px', disableClose: true})
                        .afterClosed().pipe(take(1))
                        .subscribe(
                            () => this.localStorage.setItem(this.TERMS_ARE_APPROVED, true)
                                .pipe(take(1))
                                .subscribe(() => {}));
                });
            }
        });
    }
}

import {Component, OnInit} from '@angular/core';
import {SearchFormComponent} from '../search-form-component';

@Component({
    selector: 'app-gender-criterion',
    templateUrl: './gender-criterion.component.html',
    styleUrls: ['./gender-criterion.component.css']
})
export class GenderCriterionComponent implements SearchFormComponent, OnInit {

    model = {
        h: false,
        s: false,
        v: false,
    };

    readonly genderMap: Map<string, string> = new Map<string, string>([
        ['h', 'hingst'],
        ['s', 'sto'],
        ['v', 'valack']
    ]);


    constructor() {
    }

    ngOnInit() {
    }

    validationError(): string {
        const keys = Object.keys(this.model);
        const checked: number = keys.filter(k => this.model[k]).length;
        if (checked === 0) {
            return 'Minst ett kön måste kryssas i.';
        }
        if (checked === keys.length) {
            return 'En sökning med alla kön är meningslös, ta hellre bort sökvillkoret.';
        }
    }

    initModel(model: any) {
        this.model = model;
    }

    retrieveExtra() {
        return {
            desc: Object.keys(this.model)
                .filter(k => (this.model)[k])
                .map(k => this.genderMap.get(k))
                .join(', ')
        };
    }

    retrieveModel() {
        return this.model;
    }


}

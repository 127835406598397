<div class="duel-container">
  <app-duel-form></app-duel-form>
  <table id="duels-table" *ngIf="duels.length > 0" class="duels-table">
    <th class="left-align">Duellant</th>
    <th class="right-align"
        matTooltip="BRIX (Blood Bank Racing indeX) i medeltal de av hingstens avkommor som ingår i duellen">BRIX
    </th>
    <th class="right-align" matTooltip="Antal avkommor som är bättre än halvsyskonet hos motståndaren">Vinnare</th>
    <th></th>
    <ng-container *ngFor="let d of duels">
      <tr class="duel-start-row">
        <td [ngClass]="{'winner': winner1(d), 'loser': winner2(d)}" class="duel-start-col left-align">{{ d.sire1 }}</td>
        <td [ngClass]="{'winner': winner1(d), 'loser': winner2(d)}" class="right-align">{{ d.avg1 | number:'1.1-1' }}
        </td>
        <td [ngClass]="{'winner': winner1(d), 'loser': winner2(d)}" class="right-align">{{ d.wins1 }}</td>
        <td class="right-align duel-start-col duel-end-col" rowspan="2">
          <button mat-icon-button (click)="toggleExpansion(d)">
            <mat-icon *ngIf="d.expanded">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!d.expanded">chevron_right</mat-icon>
          </button>
        </td>
      </tr>
      <tr class="duel-end-row">
        <td [ngClass]="{'winner': winner2(d), 'loser': winner1(d)}" class="duel-start-col left-align">{{ d.sire2 }}</td>
        <td [ngClass]="{'winner': winner2(d), 'loser': winner1(d)}" class="right-align">{{ d.avg2 | number:'1.1-1' }}
        </td>
        <td [ngClass]="{'winner': winner2(d), 'loser': winner1(d)}" class="right-align">{{ d.wins2 }}</td>
      </tr>
      <tr *ngIf="d.expanded" class="bordered">
        <td colspan="4" class="duel-content">
          <app-duel-record [duel]="d"></app-duel-record>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="separator"></td>
      </tr>
    </ng-container>

  </table>
</div>




// TODO: move

export const evaluationCategoryLabels =  [
  '',
  'Mycket lågt',
  'Lågt',
  'Medelmåttligt',
  'Högt',
  'Mycket högt'];

export const productionValueLabels = [
  '',
  'C',
  'B',
  'AB',
  'A',
  'Elit'];

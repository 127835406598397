import {Component, Input} from '@angular/core';
import {Horse} from '../../model/horse';

export type HorseDisplaySize = 'small' | 'medium';

@Component({
  selector: 'app-horse-display',
  templateUrl: './horse-display.component.html',
  styleUrls: ['./horse-display.component.css']
})
export class HorseDisplayComponent  {

  @Input() horse: Horse;
  @Input() size: HorseDisplaySize = 'medium';
}

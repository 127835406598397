import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NewsItem} from '../model/newsItem';
import {NewsService} from '../news.service';
import {switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Component({
    selector: 'app-news-item-details',
    templateUrl: './news-item-details.component.html',
    styleUrls: ['./news-item-details.component.css']
})
export class NewsItemDetailsComponent implements OnInit {

    item: Observable<NewsItem>;

    constructor(private router: Router, private route: ActivatedRoute, private newsService: NewsService) {
    }

    ngOnInit() {
        this.item = this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.newsService.getNewsItem(Number(params.get('newsItemId'))))
        );
    }

}

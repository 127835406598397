import {Component, ComponentFactoryResolver, Inject, OnInit, ViewChild} from '@angular/core';
import {SearchCriterionFormDirective} from '../search-criterion-form-directive';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SearchFormComponent} from '../search-form-component';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-search-criterion-form-dialog',
    templateUrl: './search-criterion-form-dialog.component.html',
    styleUrls: ['./search-criterion-form-dialog.component.css']
})
export class SearchCriterionFormDialogComponent implements OnInit {

    private formComponent: SearchFormComponent;

    @ViewChild(SearchCriterionFormDirective, {static: true}) searchCriterionForm: SearchCriterionFormDirective;
    okButtonLabel = 'Lägg till villkor';
    validationError: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<SearchCriterionFormDialogComponent>,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.crit.component);
        const viewContainerRef = this.searchCriterionForm.viewContainerRef;
        const componentRef = viewContainerRef.createComponent(componentFactory);
        this.formComponent = <SearchFormComponent>componentRef.instance;
        if (this.data.model) {
            this.okButtonLabel = 'Spara villkor';
            this.formComponent.initModel(this.data.model, this.data.extra);
        }
        this.dialogRef.disableClose = true;
        this.dialogRef.keydownEvents().pipe(
            // Reagera på escape
            tap(k => console.log(k))
        );
    }

    onSubmit() {
        if (this.formComponent.validationError()) {
            this.validationError = this.formComponent.validationError();
            return;
        }
        this.dialogRef.close(
            {
                model: this.formComponent.retrieveModel(),
                extra: this.formComponent.retrieveExtra()
            });
    }

    onCancel() {
        this.dialogRef.close(false);
    }
}

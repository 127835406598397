<!--<h2 mat-dialog-title>Hingstduellen</h2>-->
<mat-dialog-content>
  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <ng-template matStepperIcon="edit">
      <div class="icon-content">
        <mat-icon>done</mat-icon>
      </div>
    </ng-template>

    <mat-step label="Vad är hingstduellen?">
      <app-help-section [first]="true" label="Vad är hingstduellen?" (close)="onClose()">
        Hingstduellen är ett sätt att jämföra två hingstar genom att jämföra de avkommor de har med gemensamma ston
        (halvsyskon). Poängen med detta är att jämförelsen ska vara så rättvis som möjligt:
        <ul>
          <li>Stomaterial är identiskt</li>
          <li>Med samma moder följer ofta
            <ul>
              <li>samma uppfödare</li>
              <li>större likheter i tränare</li>
            </ul>
          </li>
        </ul>
      </app-help-section>
    </mat-step>
    <mat-step label="Vilka hästar omfattas?">
      <app-help-section label="Vilka hästar omfattas?" (close)="onClose()">
        I hingstduellen ingår (hittills) endast varmblodiga svenskfödda och danskfödda hästar minst 4 år och tidigast
        födda 1979.
        <p>De halvsyskon som jämförs måste vara födda i samma land och det får vara maximalt tre års åldersskillnad mellan d
          em (läs mer under "treårsregeln"). </p>
      </app-help-section>
    </mat-step>
    <mat-step label="Viktigt att komma ihåg">
      <app-help-section label="Viktigt att komma ihåg" (close)="onClose()">
        <ul>
          <li>Hingstduellen jämför bara två hingstar och kan inte säga vilken hingst som är bäst av en hel grupp</li>
          <li>Ofta utgörs underlaget av ett fåtal avkommor vilket medför att slumpen har stort inflytande</li>
        </ul>
        <strong>Hingstduellen endast tänkt att vara ett komplement till BLUP-tal och befintlig hingststatstik!</strong>
      </app-help-section>
    </mat-step>
    <mat-step label="Vilket halvsyskon är bäst?">
      <app-help-section label="Vilket halvsyskon är bäst?" (close)="onClose()">
        <p>Det är inte helt självklart hur man jämför två hästars tävlingsprestationer med varandra:</p>
        <ul>
          <li>Hur jämför man en äldre häst mot en yngre?</li>
          <li>Hur jämför man ston mot hingstar/valacker</li>
          <li>Hur värderar man ostartade hästar mot sådana som startat men presterat dåligt?</li>
          <li>Vad är värt mest?
            <ul>
              <li>att slita ihop mycket pengar på många starter med låg segerprocent?</li>
              <li>att tjäna mycket pengar på få starter med hög segerprocent?</li>
            </ul>
          </li>
        </ul>
        <p>För att lösa detta problem har vi infört ett prestationsindex, kallat BRIX.</p>
      </app-help-section>
    </mat-step>
    <mat-step label="BRIX">
      <app-help-section label="BRIX" (close)="onClose()">
        <ul>
          <li>BRIX (Blood bank Racing Index) Baseras på föjande mätvärden:
            <ul>
              <li>Rekord</li>
              <li>Prissumma</li>
              <li>Prissumma/start</li>
              <li>Segerprocent</li>
            </ul>
          </li>
          <li>Varje häst jämförs bara med andra hästar med samma nationalitet, kön och födelseår.</li>
          <li>Alla hästar som är sämre än medianhästen anses jämndåliga och har BRIX = 0
            <ul>
              <li>Detta motsvarar alltså alla ostartade hästar samt den sämsta delen av de startade.</li>
            </ul>
          </li>
        </ul>
      </app-help-section>
    </mat-step>
    <mat-step label="Vilken hingst vinner en duell?">
      <app-help-section label="Vilken hingst vinner en duell?" (close)="onClose()">
        <img src="assets/img/mh-rc.png" style="float: right" alt="Exempelduell"/>
        Det finns flera tänkbara sätt att avgöra vilken hingst som vinner en duell.
        Ett är att räkna antalet avkommor som är bättre att sitt halvsyskon.
        Då missar man emellertid att ha hänsyn till hur mycket bättre halvsyskonet är. Istället
        används ett medel-BRIX för avkommorna som ingår i duellen.
        <p>En vinnare (i en duell eller bland två halvsyskon)
          färgkodas med grönt och en förlorare med rött.
      </app-help-section>
    </mat-step>
    <mat-step label="Treårsregeln">
      <app-help-section [last]="true" label="Treårsregeln" (close)="onClose()">
        Hingstduellen är som nämnts tänkt att vara en så rättvis jämförelse som möjligt men avelsframsteget
          gör att en tidig avkommor har lättare att bli framträdande på grund av sämre konkurrens.
          Av detta skäl är alla dueller begränsade till att innehålla halvsyskon där det skiljer
          <strong>max tre i år i ålder.</strong>
        <p>Denna regel har även andra positiva effekter:
        <ul>
          <li>närhet i tid gör det mer troligt att uppfödare och miljöfaktorer är lika</li>
          <li>max tre års skillnad gör att en tidigare bra avkomma inte påverkat hingstvalet för en senare avkomma
            i samma duell (den äldre avkomman kommer att vara tidig 2-åring vid hingstvalet). En sådan korrelation
            mellan den tidiga avkommans prestationer och den senares faders avelsvärde skulle kunna ge skevheter som gynnar
            sämre hingstar i duellerna.
          </li>
        </ul>
      </app-help-section>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

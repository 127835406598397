import {Component, OnDestroy, OnInit} from '@angular/core';
import {DuelService} from './duel.service';
import {Duel} from '../model/duel';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-duel',
  templateUrl: './duel.component.html',
  styleUrls: ['./duel.component.css']
})
export class DuelComponent implements OnDestroy, OnInit {


  duels: Duel[] = [];

  subscription: Subscription;

  constructor(private duelService: DuelService, private route: ActivatedRoute) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(params => {
      const sire1 = params['sire1'];
      const sire2 = params['sire2'];
      if (sire1 && sire2) {
        this.createDuel(sire1, sire2);
      } else if (sire1) {
        this.findDuels(sire1);
      }
    });
  }

  createDuel(sire1, sire2): void {
    this.duelService.createDuel(sire1, sire2).pipe(take(1)).subscribe(duel => {
      this.duels = [duel];
      duel.expanded = true;
      this.scrollToForm();
    });
  }

  findDuels(sire1) {
    this.duelService.findDuels(sire1).pipe(take(1)).subscribe(duels => {
      this.duels = duels;
      this.scrollToForm();
    })
  }

  toggleExpansion(d: Duel) {
    d.expanded = !d.expanded;
  }

  winner1(duel: Duel): boolean {
    return duel.avg1 > duel.avg2;
  }

  winner2(duel: Duel): boolean {
    return duel.avg2 > duel.avg1;
  }

  private scrollToForm(): void {
    const duelForm: HTMLElement = <HTMLElement>document.querySelector('#results-container');
    if (duelForm) {
      duelForm.scrollIntoView(true);
    }
  }


}

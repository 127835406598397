import {Column} from "../model/column";
import {StallionResult} from "../model/stallionResult";
import {Blup} from "../model/blup";
import {evaluationCategoryLabels, productionValueLabels} from "../model/stallion";
export const STALLION_COLUMNS: Column[] = [

  {
    identifier: 'name',
    heading: 'Namn',
    description: 'Hingstens namn',
    defaultVisible: true,
    fixed: true,
    hidden: false,
    css: { 'left': true },
    extractorFunction: (s: StallionResult) => s.name
  },
  {
    identifier: 'method',
    heading: 'Metod',
    description: 'Reproduktionsmetod',
    defaultVisible: true,
    fixed: true,
    hidden: false,
    css: { 'left': true },
    extractorFunction: (s: StallionResult) => s.reproductionMethod
  },
  {
    identifier: 'fee0',
    heading: 'Avgift före föl',
    description: 'Avgifter som erläggs oavsett om levande föl föds (till exempel boknings-, språng- eller dräktighetsavgift)',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => s.fee0.desc,
    sortValueExtractor: (s: StallionResult) => s.fee0.amountInSek

  },
  {
    identifier: 'fee1',
    heading: 'Fölavgift',
    description: 'Fölavgift, erläggs normalt vid föl som lever i minst 48 timmar och är försäkringsbart',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => s.fee1.desc,
    sortValueExtractor: (s: StallionResult) => s.fee1.amountInSek
  },
  {
    identifier: 'blup',
    heading: 'Avelsindex',
    description: 'Avelsindex (totalindex), beräknat enligt BLUP-metoden',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => extractBlupIndex(s.blup, 'totalIndex'),

  },
  {
    identifier: 'blupNumberOfStarts',
    heading: 'BLUP (AS)',
    description: 'Delindex för antal starter. Utgör 5 procent av totalindex.',
    defaultVisible: false,
    hidden: true,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => extractBlupIndex(s.blup, 'numberOfStartsIndex'),
  },
  {
    identifier: 'blupPerformance',
    heading: 'BLUP (TP)',
    description: 'Delindex för tävlingsprestationer. Utgör 75 % av totalindex.',
    defaultVisible: false,
    hidden: true,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => extractBlupIndex(s.blup, 'performanceIndex'),
  },
  {
    identifier: 'blupStarterFrequency',
    heading: 'BLUP (SS)',
    description: 'Delindex för startstatus. Utgör 20 % av totalindex.',
    defaultVisible: false,
    hidden: true,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => extractBlupIndex(s.blup, 'starterFrequencyIndex')
  },
  {
    identifier: 'accuracy',
    heading: 'Säkerhet',
    description: 'Säkerheten i avelsindexet. Ju närmare 1 detta tal är, desto säkrare är avelsindexets skattning.',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => (extractBlupIndex(s.blup, 'accuracy') / 100)
      .toLocaleString('sv-SE', { minimumFractionDigits: 2 })
  },
  {
    identifier: 'evaluationCategory',
    heading: 'Avelsvärde (ST)',
    description: 'Skattat avelsvärde enligt omdöme från ST:s avelsvärdering',
    defaultVisible: true,
    hidden: false,
    css: { 'center': true },
    extractorFunction: (s: StallionResult) => evaluationCategoryLabels[s.evaluationCategory],
    sortValueExtractor: (s: StallionResult) => s.evaluationCategory,

  },
  {
    identifier: 'productionValue',
    heading: 'Avkommebedömning (ST)',
    description: 'Värdeomdöme enligt ST:s avkommebedömning',
    defaultVisible: true,
    hidden: false,
    css: { 'center': true },
    extractorFunction: (s: StallionResult) => productionValueLabels[s.productionValue],
    sortValueExtractor: (s: StallionResult) => s.productionValue
  },
  {
    identifier: 'heightWithers',
    heading: 'Mankhöjd',
    description: 'Hingstens höjd över manken i centimeter',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => s.heightWithers
  },
  {
    identifier: 'heightCroups',
    heading: 'Korshöjd',
    description: 'Hingstens höjd över korset i centimeter',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => s.heightCroups
  },
  {
    identifier: 'bodyLength',
    heading: 'Längd',
    description: 'Hingstens längd',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true },
    extractorFunction: (s: StallionResult) => s.bodyLength
  },
  {
    identifier: 'inbreedingCoefficient',
    heading: 'F (%)',
    description: 'Det planerade fölets inavelskoeffiecent i procent',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true, 'foal-data': true, 'nowrap-header': true },
    extractorFunction: (s: StallionResult) => (s.inbreedingCoefficient * 100)
      .toLocaleString('sv-SE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }),
    sortValueExtractor: (s: StallionResult) => s.inbreedingCoefficient
  },
  {
    identifier: 'french',
    heading: 'Franskt blod (%)',
    description: 'Andel franskt blod i det planerade fölet i procent',
    defaultVisible: true,
    hidden: false,
    css: { 'right': true, 'foal-data': true },
    extractorFunction: (s: StallionResult) => (s.french * 100)
      .toLocaleString('sv-SE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }),
    sortValueExtractor: (s: StallionResult) => s.french

  },
  {
    identifier: 'foalLink',
    heading: 'Stamtavla',
    description: 'Länk till planerat föls stamtavla',
    defaultVisible: true,
    hidden: false,
    css: { 'center': true, 'foal-data': true },
    extractorFunction: (s: StallionResult) => 'https://www.travsport.se/hast/visa/' + s.idSt + '/avel?source=S'
  },

];

export function extractBlupIndex(blup: Blup, part: string) {
  if (!blup) {
    return null;
  }
  return blup[part];
}

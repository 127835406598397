<div *ngIf="!loaded" class="feedback">
  <mat-spinner [diameter]="32" [strokeWidth]="2"></mat-spinner>
</div>
<div *ngIf="loaded && recordEntries.length === 0" class="feedback">
  Inga dueller hittade
</div>
<table *ngIf="loaded && recordEntries.length > 0" class="record-table">
  <tr>
    <th>Mor</th>
    <th colspan="9">Avkomma</th>
  </tr>
  <ng-container *ngFor="let r of recordEntries; let last = last">
    <tr class="duel-start-row">
      <td class="duel-end-row duel-end-col" rowspan="2">{{ r.dam.name }}</td>

      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}">{{ r.foal1.name }}&nbsp;({{ r.foal1.nationality }})</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}">{{ r.foal1.gender }}</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}">{{ r.foal1.birthYear }}</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}">{{ r.foal1.timeDesc }}</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}" class="right-align nowrap">{{ r.foal1.earningsDesc }}</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}" class="right-align">{{ r.foal1.starts }}</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}" class="nowrap">{{ r.foal1.placeDesc }}</td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}"
        class="right-align"
          matTooltip="BRIX (Blood Bank Racing indeX), ett mått på hästens prestation">
        {{ r.foal1.brix | number:'1.1-1' }}
      </td>
      <td [ngClass]="{'winner': winner1(r), 'loser': winner2(r)}">{{ duel.sire1 }}</td>

      <!--<td rowspan="2">{{ winnerDesc(r)}}</td>-->
    </tr>
    <tr class="duel-end-row">
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}">{{ r.foal2.name }}&nbsp;({{ r.foal2.nationality }})</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}">{{ r.foal2.gender }}</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}">{{ r.foal2.birthYear }}</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}">{{ r.foal2.timeDesc }}</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}" class="right-align nowrap">{{ r.foal2.earningsDesc }}</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}" class="right-align">{{ r.foal2.starts }}</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}" class="nowrap">{{ r.foal2.placeDesc }}</td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}"
          class="right-align"
          matTooltip="BRIX (Blood Bank Racing indeX), ett mått hästens prestation">
        {{ r.foal2.brix | number:'1.1-1' }}
      </td>
      <td [ngClass]="{'winner': winner2(r), 'loser': winner1(r)}">{{ duel.sire2 }}</td>
    </tr>
    <tr *ngIf="!last">
      <td colspan="10" class="separator"></td>
    </tr>
  </ng-container>

</table>

import {Component, Input, OnInit} from '@angular/core';
import {NewsItem} from '../model/newsItem';

// TODO: rename to NewsLinkComponent ?
@Component({
  selector: 'app-newsitem',
  templateUrl: './newsitem.component.html',
  styleUrls: ['./newsitem.component.css']
})
export class NewsitemComponent implements OnInit {
  @Input() item: NewsItem;

  constructor() { }

  ngOnInit() {
  }

}

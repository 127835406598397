import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../config.service';
import {SearchSpec} from '../production-record/search-spec';
import {SearchResult} from '../model/search-result';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private config: ConfigService, private httpClient: HttpClient) { }

  search(searchSpec: SearchSpec): Observable<SearchResult> {
    return this.httpClient
        // TODO: return SearchResult
        .post<SearchResult>(this.config.getBackEndHost() + '/search', searchSpec);

  }
}

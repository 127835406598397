import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {evaluationCategoryLabels, productionValueLabels} from '../model/stallion';
import {StallionService} from '../stallion.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchParams} from './searchParams';
import {StallionResult} from '../model/stallionResult';
import {Subscription} from 'rxjs';
import {ConformationProperty} from './conformationProperty';
import {ColumnSelectorComponent} from '../column-selector/column-selector.component';
import {Column} from '../model/column';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {STALLION_COLUMNS} from './stallion-columns';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-breeders-guide',
  templateUrl: './breeders-guide.component.html',
  styleUrls: ['./breeders-guide.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BreedersGuideComponent implements AfterViewInit, OnDestroy, OnInit {

  conformationProperties: ConformationProperty[];
  conformationDeviations: String[];

  readonly eLabels = evaluationCategoryLabels;
  readonly pLabels = productionValueLabels;

  columns: Column[] = STALLION_COLUMNS;

  formGroup: FormGroup;

  loading = true;

  readonly pageSize = 30;

  tableHeight: any;

  stallions: StallionResult[];

  // TODO: centralize state and use onPush
  currentPageIndex = 0;
  currentSort: Sort = <Sort>{
    active: 'blup',
    direction: 'desc'
  };

  private subscription: Subscription;

  readonly HIDDEN_COLS_KEY: string = '#HIDDEN_COLS#';

  constructor(public dialog: MatDialog,
              private localStorage: LocalStorage,
              private stallionService: StallionService,
              private paginator: MatPaginatorIntl,
              private cdr: ChangeDetectorRef) {

    this.getScreenSize();

    this.initColumns();

    this.formGroup = new FormGroup({
      mare: new FormControl(null, null),
      fee: new FormControl({min: 0, max: 500000}, null),
      frozen: new FormControl(true, null),
      fresh: new FormControl(true, null),
      heightWithers: new FormControl({min: 140, max: 180}, null),
      includeUnknownHeightWithers: new FormControl(true, null),
      heightCroup: new FormControl({min: 140, max: 180}, null),
      includeUnknownHeightCroup: new FormControl(true, null),
      bodylength: new FormControl({min: 140, max: 180}, null),
      includeUnknownBodyLength: new FormControl(true, null),
      desired: new FormControl([], null),
      unwanted: new FormControl([], null),
      ic: new FormControl({min: 0, max: 50}, null),
      french: new FormControl({min: 0, max: 100}, null)
    });
  }

  // calculateTableHeight(): string {
  //   const height = Math.max(160, this.screenHeight - 220);
  //   return height + 'px'
  // }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    const heightInPixels = Math.max(160, window.innerHeight - 130);
    this.tableHeight = heightInPixels + 'px';
  }

  ngAfterViewInit() {
    // We only want to detach the change detectors after change detection has been
    // performed for the first time
    // this.cdr.detach();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loading = true;

    this.stallionService.getConformationProperties().pipe(take(1)).subscribe(props => {
      this.conformationProperties = props;
    });

    this.stallionService.getConformationDeviations().pipe(take(1)).subscribe(devs => {
      this.conformationDeviations = devs;
    });

    this.stallionService.findStallions(this.createSearchParams(this.formGroup.value)).pipe(take(1)).subscribe(stallions => {
      this.stallions = stallions;
      this.sortStallions(this.currentSort);
      this.loading = false;
    });

    this.subscription = this.formGroup.valueChanges.subscribe(val => {
      this.loading = true;
      this.currentPageIndex = 1;
      this.stallionService.findStallions(this.createSearchParams(this.formGroup.value)).pipe(take(1)).subscribe(stallions => {
        this.stallions = stallions;
        this.sortStallions(this.currentSort);
        this.loading = false;
      });
    });
  }

  onPage(event: PageEvent) {
    this.currentPageIndex = event.pageIndex;
  }

  getPage(): StallionResult[] {
    return this.stallions.slice(this.currentPageIndex * this.pageSize, (this.currentPageIndex + 1) * this.pageSize);
  }

  onSelectColumns(): void {
    const dialogRef: MatDialogRef<ColumnSelectorComponent> = this.dialog.open(ColumnSelectorComponent, {
      data: {
        cols: STALLION_COLUMNS.filter(col => !col.fixed)
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(cols => {

      const serializedHiddenCols: string = cols
        .filter(col => col.hidden)
        .map(col => col.identifier)
        .join(',');

      this.localStorage.setItem(this.HIDDEN_COLS_KEY, serializedHiddenCols)
          .pipe(take(1)).subscribe(result => {
          this.columns = this.visibleColumns();
        });
    });
  }

  onSubmit(): void {
    this.stallionService.findStallions(this.createSearchParams(this.formGroup.value)).pipe(take(1)).subscribe(stallions => {
      this.stallions = stallions;
    });
  }

  private createSearchParams(value: any): SearchParams {
    const params = <SearchParams>{
      minFee: value.fee.min,
      maxFee: value.fee.max,
      minHeightWithers: value.heightWithers.min,
      maxHeightWithers: value.heightWithers.max,
      includeUnknownHeightWithers: value.includeUnknownHeightWithers,
      minHeightCroups: value.heightCroup.min,
      maxHeightCroups: value.heightCroup.max,
      includeUnknownHeightCroup: value.includeUnknownHeightCroup,
      minBodyLength: value.bodylength.min,
      maxBodyLength: value.bodylength.max,
      includeUnknownBodyLength: value.includeUnknownBodyLength,
      frozen: value.frozen,
      fresh: value.fresh,
      desiredAncestors: value.desired.map(stallion => stallion.id),
      unwantedAncestors: value.unwanted.map(stallion => stallion.id),
      minIC: value.ic.min,
      maxIC: value.ic.max,
      minFrench: value.french.min,
      maxFrench: value.french.max
    };

    if (value.mare) {
      params.mare = value.mare.id;
    }
    return params;
  }

  sortStallions(sort: Sort) {
    this.currentSort = sort;
    const data = this.stallions.slice();
    if (!this.currentSort.active) {
      this.currentSort.active = 'blup';
    }
    if (this.currentSort.direction === '') {
      this.currentSort.direction = 'desc';
    }
    let sortCol: Column = this.columns.filter(col => col.identifier === this.currentSort.active)[0];
    if (!sortCol) {
      sortCol = STALLION_COLUMNS[0];
    }
    this.stallions = data.sort((a: StallionResult, b: StallionResult) => {
      const isAsc = this.currentSort.direction === 'asc';
      if (sortCol.sortValueExtractor) {
        return compare(sortCol.sortValueExtractor(a), sortCol.sortValueExtractor(b), isAsc);
      } else {
        return compare(sortCol.extractorFunction(a), sortCol.extractorFunction(b), isAsc);
      }

    });
  }



  visibleColumns(): Column[] {
    return STALLION_COLUMNS.filter(col => !col.hidden);
  }

  private initColumns() {
    this.columns = STALLION_COLUMNS;
    this.localStorage.getItem<string>(this.HIDDEN_COLS_KEY, {schema: {type: 'string'}}).pipe(take(1))
      .subscribe(value => {
        const hiddenCols: string[] = value ? value.split(',') : [];
        this.columns = STALLION_COLUMNS.map(col => {
          if (hiddenCols.includes(col.identifier)) {
            col.hidden = true;
          }
          return col;
        });
        // TODO: seems to be a reduntant representation of hidden here
        this.columns = this.visibleColumns();
      });
  }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


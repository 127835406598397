import {Component, OnInit} from '@angular/core';
import {SearchFormComponent} from '../search-form-component';

@Component({
    selector: 'app-nationality-criterion',
    templateUrl: './nationality-criterion.component.html',
    styleUrls: ['./nationality-criterion.component.css']
})
export class NationalityCriterionComponent implements SearchFormComponent, OnInit {

    model = {};

    nationalities = [
        'AT',
        'AU',
        'BE',
        'CA',
        'CH',
        'CZ',
        'DE',
        'DK',
        'EE',
        'ES',
        'FI',
        'FR',
        'GB',
        'HU',
        'IT',
        'LT',
        'NL',
        'NO',
        'NZ',
        'RO',
        'RS',
        'RU',
        'SE',
        'SI',
        'SU',
        'US',
        'YU',
        'Övriga'
    ];

    constructor() {
    }

    ngOnInit() {
    }

    validationError(): string {
        const checked: number = Object.keys(this.model).filter(k => this.model[k]).length;
        if (checked === 0) {
            return 'Minst en nationalitet måste kryssas i.';
        }
        if (checked === this.nationalities.length) {
            return 'En sökning med alla nationaliteter är meningslös, ta hellre bort sökvillkoret.';
        }
    }

    initModel(model: any) {
        this.model = model;
    }
   
    retrieveExtra() {
        return null;
    }

    retrieveModel() {
        return this.model;
    }
}

import { Component, OnInit } from '@angular/core';
import {SearchFormComponent} from '../search-form-component';

@Component({
  selector: 'app-breed-criterion',
  templateUrl: './breed-criterion.component.html',
  styleUrls: ['./breed-criterion.component.css']
})
export class BreedCriterionComponent implements SearchFormComponent, OnInit {

  model = {
    warm: false,
    cold: false,
  };

  readonly breedMap: Map<string, string> = new Map<string, string>([
    ['warm', 'varmblodiga hästar'],
    ['cold', 'kallblodiga hästar'],
  ]);


  constructor() { }

  ngOnInit() {
  }

  initModel(model: any, extra?: any): void {
    this.model = model;
  }

  retrieveExtra(): any {
    return {
      desc: Object.keys(this.model)
          .filter(k => (this.model)[k])
          .map(k => this.breedMap.get(k))
          .join(', ')
    };
  }

  retrieveModel(): any {
    return this.model;
  }

  validationError(): string {
    const keys = Object.keys(this.model);
    const checked: number = keys.filter(k => this.model[k]).length;
    if (checked === 0) {
      return 'Minst en ras måste kryssas i.';
    }
    if (checked === keys.length) {
      return 'En sökning med alla raser är meningslös, ta hellre bort sökvillkoret.';
    }
  }

}

<div class="section">

    <section class="mat-typography">
        <h2>Hingstduellen</h2>
        <p>Jämför två hingstar baserat på avkommor undan gemensamma mödrar.
            <button mat-icon-button (click)="openDialog()">
                <mat-icon>help_outline</mat-icon>
            </button>
        </p>
    </section>

    <div id="duel-form" class="form-container">
        <fieldset class="form-container">
            <legend>Skapa duell mellan två hingstar</legend>
            <app-horse-field label="hingst 1" [searchFilter]="searchFilter"
                            (selected)="onSelected1($event)"></app-horse-field>
            <app-horse-field label="hingst 2" [searchFilter]="searchFilter"
                            (selected)="onSelected2($event)"></app-horse-field>
        </fieldset>

        <fieldset class="form-container" style="margin-top: 15px">
            <legend>Sök dueller omfattande flest halvsyskon</legend>
            <app-horse-field label="hingst" [searchFilter]="searchFilter"
                            (selected)="onSelected($event)"></app-horse-field>
        </fieldset>

    </div>
</div>

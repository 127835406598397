import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {StallionResult} from '../../model/stallionResult';
import {Column} from '../../model/column';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-breeders-guide-table',
  templateUrl: './breeders-guide-table.component.html',
  styleUrls: ['./breeders-guide-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreedersGuideTableComponent {

  @Input() columns: Column[] = [];

  @Input() height: string;

  @Input() mareId: number;

  @Input() pageSize = 30;

  @Input() stallions: StallionResult[];

  @Output() sortChange = new EventEmitter();


  colId(index: number, col: Column): string {
    return col.identifier;
  }

  displayedColumns(): string[] {
    return this.columns
      .filter(col => !col.hidden)
      .map(col => col.identifier);
  }

  emitSort(sort: Sort) {
    this.sortChange.emit(sort);
  }

  generateFoalLink(sireId: number): string {
    let link: string =
      'http://www.blodbanken.nu/servlet/GetBBData?f='
      + sireId;
    if (this.mareId) {
      link = link + '&m=' + this.mareId;
    }
    return link;
  }

  generateStLink(idSt: number): string {
    return 'https://www.travsport.se/hast/visa/' + idSt + '/avel?source=S';
  }


}

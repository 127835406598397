import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SearchCriterionType} from './search-criterion-type';
import {GenderCriterionComponent} from './gender-criterion/gender-criterion.component';
import {SearchCriterionFormDialogComponent} from './search-criterion-form-dialog/search-criterion-form-dialog.component';
import {YearCriterionComponent} from './year-criterion/year-criterion.component';
import {AncestorPositionCriterionComponent} from './ancestor-position-criterion/ancestor-position-criterion.component';
import {SearchService} from './search.service';
import {DataSourceState, SearchDataSource} from './search-datasource';
import {CriterionSpec, SearchSpec} from '../production-record/search-spec';
import {SearchCriterion} from './search-criterion';
import {NationalityCriterionComponent} from './nationality-criterion/nationality-criterion.component';
import {InbreedingCriterionComponent} from './inbreeding-criterion/inbreeding-criterion.component';
import {BreedCriterionComponent} from './breed-criterion/breed-criterion.component';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnDestroy, OnInit {

    readonly criteriaTypes: SearchCriterionType[] = [
        {
            id: 'BREED',
            label: 'Ras',
            component: BreedCriterionComponent,
            multipleAllowed: false,
            render(model: any, extra: any): string {
                return extra.desc;
            }
        },
        {
            id: 'GENDER',
            label: 'Kön',
            component: GenderCriterionComponent,
            multipleAllowed: false,
            render(model: any, extra: any): string {
                return extra.desc;
            }
        },
        {
            id: 'BIRTH_YEAR',
            label: 'Födelseår',
            component: YearCriterionComponent,
            multipleAllowed: false,
            render(model: any): string {
                if (model.minYear === model.maxYear) {
                    return model.minYear.toString();
                }
                return `Mellan ${model.minYear} och ${model.maxYear}`;
            }

        },
        {
            id: 'NATIONALITY',
            label: 'Födelseland',
            component: NationalityCriterionComponent,
            multipleAllowed: false,
            render(model: any): string {
                return Object.keys(model).filter(k => model[k]).join(', ');
            }
        },
        {
            id: 'ANCESTOR_POSITION',
            label: 'Anförälder',
            component: AncestorPositionCriterionComponent,
            multipleAllowed: true,
            render(model: any, extra: any): string {
                if (extra.position) {
                    return `${extra.horse.name} som ${extra.position}`;
                } else {
                    return `${extra.horse.name} ${extra.sectionText} inom ${model.minDepth} - ${model.maxDepth} led`;
                }
            }

        },
        {
            id: 'INBREEDING',
            label: 'Inavel',
            component: InbreedingCriterionComponent,
            multipleAllowed: true,
            render(model: any, extra: any): string {
                if (model.pattern.length === 3) {
                    return `${model.pattern} på ${extra.horse.name}`;
                } else {
                    return `Inavel på ${extra.horse.name}`;
                }
            }
        }
/*
        ,
        {
            id: 'RECORD_MARK',
            label: 'Rekord',
            component: GenderCriterionComponent,
            multipleAllowed: false,
            render(model: any): string {
                return 'Ej klar';
            }
        },
        {
            id: 'EARNINGS',
            label: 'Prissumma',
            component: GenderCriterionComponent,
            multipleAllowed: false,
            render(model: any): string {
                return 'Ej klar';
            }
        },
        {
            id: 'STARTS',
            label: 'Starter',
            component: GenderCriterionComponent,
            multipleAllowed: false,
            render(model: any): string {
                return 'Ej klar';
            }
        },
        {
            id: 'WINS',
            label: 'Vinster',
            component: GenderCriterionComponent,
            multipleAllowed: false,
            render(model: any): string {
                return 'Ej klar';
            }
        }, */
    ];
    criteria: SearchCriterion[] = [];
    datasource: SearchDataSource;
    state: DataSourceState;
    private subscription: Subscription;

    constructor(public dialog: MatDialog, private searchService: SearchService) {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.datasource = new SearchDataSource(this.searchService);
        this.subscription = this.datasource.state$.subscribe(s => this.state = s);
    }

    addCriterion(criterionType: SearchCriterionType) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(SearchCriterionFormDialogComponent, {
            data: {
                crit: criterionType
            }
        });
        dialogRef.afterClosed().pipe(
            take(1)
        ).subscribe(result => {
            if (result) {
                this.criteria.push({
                    criterionType: criterionType,
                    model: result.model,
                    extra: result.extra
                });
                this.datasource.reset();
            }
        });
    }

    removeCriterion(index: number) {
        this.criteria.splice(index, 1);
        this.datasource.reset();
    }

    editCriterion(index: number) {
        const criterionType = this.criteria[index].criterionType;
        const model = Object.assign({}, this.criteria[index].model);
        const extra = Object.assign({}, this.criteria[index].extra);
        const dialogRef: MatDialogRef<any> = this.dialog.open(SearchCriterionFormDialogComponent, {
            data: {
                crit: criterionType,
                model: model,
                extra: extra
            }
        });
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(result => {
            if (result) {
                this.criteria.splice(index, 1, {
                    criterionType: criterionType,
                    model: result.model,
                    extra: result.extra
                });
                this.datasource.reset();
            }
        });
    }

    onSearch() {
        const criteriaSpecs: CriterionSpec[] = this.criteria
            .map(criterion => {
                return {
                    criterionType: criterion.criterionType.id,
                    model: criterion.model
                };
            });
        const searchSpec: SearchSpec = {
            criteria: criteriaSpecs,
            offset: 0
        };
        this.datasource.newSearch(searchSpec);
    }

    criterionSelected(crit: SearchCriterionType): SearchCriterion {
        return this.criteria.find(c => c.criterionType === crit);
    }
}

import {Component, OnInit} from '@angular/core';
import {SearchFormComponent} from '../search-form-component';
import {Horse} from '../../model/horse';

interface PedigreeOption {
    readonly id: string;
    readonly text: string;
}

@Component({
    selector: 'app-ancestor-position-criterion',
    templateUrl: './ancestor-position-criterion.component.html',
    styleUrls: ['./ancestor-position-criterion.component.css']
})
export class AncestorPositionCriterionComponent implements SearchFormComponent, OnInit {

    allPositions: PedigreeOption[];
    readonly allSections: PedigreeOption[] = [
        {
            id: 'ALL_PEDIGREE',
            text: 'varsomhelst'
        }, {
            id: 'PATERNAL_SIDE',
            text: 'på faderssidan'
        }, {
            id: 'PATERNAL_LINE',
            text: 'på faderslinjen'
        }, {
            id: 'MATERNAL_SIDE',
            text: 'på moderssidan'
        }, {
            id: 'MATERNAL_LINE',
            text: 'på moderslinjen'
        }, {
            id: 'X_POSTITION',
            text: 'i X-position'
        }
    ];

    options = {
        floor: 1,
        ceil: 8,
        showTicks: true
        // TODO: lös lång svarstid och öka på
    };
    position: string;
    positions: PedigreeOption[];
    pedigreeSection: string;
    sections: PedigreeOption[];
    selectedHorse: Horse;
    minDepth = 1;
    maxDepth = 4;
    exactExpanded = false;
    generalExpanded = false;

    constructor() {
    }

    ngOnInit() {
        this.allPositions = this.createPositions([{id: '', text: ''}]);
        this.positions = [...this.allPositions];
        this.sections = [...this.allSections];
    }

    private createPositions(queue: PedigreeOption[]): PedigreeOption[] {
        if (queue.length === 0) {
            return [];
        }
        const curr: PedigreeOption = queue.shift();
        if (curr.id.length < 6) {
            queue.push(this.f(curr), this.m(curr));
        }
        return [curr, ...this.createPositions(queue)];
    }


    onSelected(horse: Horse) {
        this.selectedHorse = horse;
        if (horse) {
            this.filterPositions();
        } else {
            this.positions = [...this.allPositions];
        }
    }

    private f(curr: PedigreeOption): PedigreeOption {
        return {
            id: curr.id + 'f',
            text: curr.text + (curr.id.length > 1 && curr.id.length % 2 === 0 ? 's' : '') + 'far'
        };
    }

    private m(curr: PedigreeOption): PedigreeOption {
        return {
            id: curr.id + 'm',
            text: curr.text + (curr.id.length > 1 && curr.id.length % 2 === 0 ? 's' : '') + 'mor'
        };
    }

    private filterPositions() {
        this.positions = this.allPositions.filter(p => this.acceptPosition(p.id));
        this.sections = this.allSections.filter(s => this.acceptSection(s.id));
        if (this.position && !this.acceptPosition(this.position)) {
            this.position = null;
        }
        if (this.pedigreeSection && !this.acceptSection(this.pedigreeSection)) {
            this.pedigreeSection = null;
        }
    }

    private acceptPosition(positionId: string): boolean {
        return positionId.endsWith(this.isMale(this.selectedHorse) ? 'f' : 'm');
    }

    private acceptSection(sectionId: string) {
        if (this.isMale(this.selectedHorse) && sectionId === 'MATERNAL_LINE') {
            return false;
        }
        if (!this.isMale(this.selectedHorse) && sectionId === 'PATERNAL_LINE') {
            return false;
        }
        return true;
    }

    validationError(): string {
        if (!this.selectedHorse) {
            return 'Vänligen välj en anförälder.';
        }
        if (this.exactExpanded) {
            return this.validateExact();
        } else if (this.generalExpanded) {
            return this.validateGeneral();
        } else {
            return 'Vänligen välj antingen exakt eller generell position i stamtavlan';
        }
    }

    private validateExact(): string {
        if (!this.position) {
            return 'Vänligen välj en position.';
        }
        return '';
    }

    private validateGeneral(): string {
        // if (this.selectedHorse.birthYear < 1965) {
        //     return 'Endast anföräldrar födda 1965 eller senare kan användas vid sökning med generell position i stamtavlan';
        // }
        if (!this.pedigreeSection) {
            return 'Vänligen välj del av stamtavlan.';
        }
        return '';
    }

    initModel(model: any, extra: any) {
        this.selectedHorse = extra.horse;
        if (model.position) {
            this.position = model.position;
            this.exactExpanded = true;
        }
        if (model.pedigreeSection) {
            this.pedigreeSection = model.pedigreeSection;
            this.minDepth = model.minDepth;
            this.maxDepth = model.maxDepth;
            this.generalExpanded = true;
        }
    }

    retrieveExtra(): any {
        if (this.exactExpanded) {
            return {
                horse: this.selectedHorse,
                position: this.findText(this.positions, this.position)
            };
        }
        if (this.generalExpanded) {
            return {
                horse: this.selectedHorse,
                sectionText: this.findText(this.sections, this.pedigreeSection)
            };
        }
        throw new Error('Either exact or general must be expanded');
    }

    retrieveModel(): any {
        if (this.exactExpanded) {
            return {
                id: this.selectedHorse.id,
                isMale: !(this.selectedHorse.gender === 's'),
                position: this.position
            };
        }
        if (this.generalExpanded) {
            return {
                id: this.selectedHorse.id,
                isMale: !(this.selectedHorse.gender === 's'),
                pedigreeSection: this.pedigreeSection,
                minDepth: this.minDepth,
                maxDepth: this.maxDepth
            };
        }
        throw new Error('Either exact or general must be expanded');
    }

    private isMale(horse: Horse) {
        return !(horse.gender === 's');
    }

    private findText(options: PedigreeOption[], id: string): string {
        return options.find(p => p.id === id).text;
    }

}

import {Component} from '@angular/core';
import {Horse} from './model/horse';
import {ConfigService} from './config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private config: ConfigService) {}

  generateLegacyLink(suffix: string): string {
    return suffix;
  }

  onSelected(horse: Horse) {
    window.open('http://blodbanken.nu/servlet/GetBBData?trotter=' + horse.id, '_blank');
  }
}

<svg *ngIf="!imgLoaded" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     width="2419"
     height="866"
     viewBox="0 0 2419 866">
  <rect width="100%" height="100%" fill="#FFFFFF"/>
</svg>

<img [hidden]="!imgLoaded" src="../../assets/img/louise-pilgaard-unsplash-cropped-smaller.jpg"
     alt="A herd of horses standing on top of a grass covered hillside by Louise Pilgaard "
    class="hero" (load)="imgLoaded = true"
/>
<div class="hero-text">
  <h1 >Välkommen till Blodbanken</h1>
  <p><i>"En guldgruva för alla som är intresserade av avel"</i></p>
</div>

<div class="start-container mat-typography">

  <!--
  <mat-nav-list>
    <h3 mat-subheader>Nyheter</h3>
    <app-newsitem *ngFor="let item of newsItems | async" [item]="item"></app-newsitem>
  </mat-nav-list>-->

  <mat-nav-list>
    <h3 mat-subheader>Missa inte</h3>
    <mat-list-item><a routerLink="/duels">Hingstduellen</a></mat-list-item>
    <mat-list-item><a routerLink="/advanced-search">Avancerad sökning</a></mat-list-item>
  </mat-nav-list>
</div>

import { ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Directive } from '@angular/core';
import {SearchFilter} from './searchFilter';
import {Horse} from '../model/horse';
import {Observable, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {HorseService} from './horse.service';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {debounceTime, take} from 'rxjs/operators';

@Directive()
export abstract class AbstractHorseFieldComponent implements OnInit {
    @Input() label: string;
    @Input() searchFilter: SearchFilter;

    @Output() selected = new EventEmitter<Horse>();

    @ViewChild('auto') autocomplete: MatAutocomplete;
    @ViewChild('horseInput') horseInput: ElementRef;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

    control: FormControl = new FormControl();
    filteredHorses: Observable<Horse[]>;
    protected subscription: Subscription;


    protected constructor(protected horseService: HorseService) {
    }

    displayFunction(horse: Horse): string {
        if (horse) {
            return horse.name;
        }
    }

    ngOnInit() {
        this.subscription = this.control.valueChanges
            .pipe(debounceTime(250))
            .subscribe(searchString => {
                if (searchString && searchString.length > 1) {
                    this.filteredHorses = this.horseService.searchHorse(searchString, this.searchFilter);
                }
            });
    }

    focus(): void {
        this.horseInput.nativeElement.focus();
    }

    forceSearch() {
        this.horseService.searchHorse(this.control.value, this.searchFilter).pipe(take(1)).subscribe(results => {
            if (results.length === 1) {
                this.horseSelected(results[0]);
                this.trigger.closePanel();
            }
        });
    }

    abstract horseSelected(horse: Horse): void;


}

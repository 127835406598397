<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened>

    <form [formGroup]="formGroup" (submit)="onSubmit()">

      <mat-vertical-stepper>
        <mat-step label="Vilket sto?">
          <app-horse-field label="Sto" formControlName="mare" [searchFilter]="{gender: 'FEMALE'}"></app-horse-field>
        </mat-step>
        <mat-step label="Budget">
          <app-slider formControlName="fee" label="total avgift" [floor]="5000" [ceil]="500000" [step]="1000" suffix=" SEK"
                      logScale="true"></app-slider>
        </mat-step>
        <mat-step label="Reproduktionsmetod">
          <div style="display: flex; flex-direction: column;">
            <mat-checkbox formControlName="fresh">Färsk sperma</mat-checkbox>
            <mat-checkbox formControlName="frozen">Fryst sperma</mat-checkbox>
          </div>
          <!--utland?-->
          <!--naturlig?-->
        </mat-step>
        <mat-step label="Härstamning">
          <!-- TODO: two columns -->
          <div style="display: flex; flex-direction: column;">
            <app-multiple-horse-field formControlName="desired" color="primary"
                                     hintLabel="ange hur många du vill"
                                     label="önskade anföräldrar"></app-multiple-horse-field>
            <app-multiple-horse-field formControlName="unwanted" color="warn"
                                     hintLabel="ange hur många du vill"
                                     label="oönskade anföräldrar"></app-multiple-horse-field>
          </div>
          <!-- flytta till panel för avkomma? -->
          <div>
            <app-slider formControlName="ic"
                        label="Inavelskoefficient (avkomma)"
                        [floor]="0"
                        [ceil]="50" suffix=" %">
            </app-slider>
          </div>
          <div>
            <app-slider formControlName="french"
                        label="Franskt blod (avkomma)"
                        [floor]="0"
                        [ceil]="100" suffix=" %">
            </app-slider>
          </div>

        </mat-step>
        <mat-step label="Storlek">
          <div>
            <app-slider formControlName="heightWithers"
                        label="Mankhöjd"
                        [floor]="140"
                        [ceil]="180" suffix=" cm">
            </app-slider>
            <mat-checkbox formControlName="includeUnknownHeightWithers">Inkludera hingstar utan uppgift</mat-checkbox>
          </div>
          <div>
            <app-slider
              formControlName="heightCroup"
              label="Korshöjd"
              [floor]="140"
              [ceil]="180"
              suffix=" cm"></app-slider>
            <mat-checkbox formControlName="includeUnknownHeightCroup">Inkludera hingstar utan uppgift</mat-checkbox>
          </div>
          <div>
            <app-slider
              formControlName="bodylength"
              label="Längd"
              [floor]="140"
              [ceil]="180"
              suffix=" cm"></app-slider>
            <mat-checkbox formControlName="includeUnknownBodyLength">Inkludera hingstar utan uppgift</mat-checkbox>
          </div>
        </mat-step>
        <!--
                <mat-step label="Exteriöra egenskaper">
                  <div class="form-table">
                    <div *ngFor="let p of conformationProperties" class="form-row">
                      <div class="form-cell"><label>{{ p.label }}</label></div>
                      <div class="form-cell">
                        <mat-checkbox [checked]="true">{{ p.lowValueLabel }}</mat-checkbox>
                      </div>
                      <div class="form-cell">
                        <mat-checkbox [checked]="true">Normal</mat-checkbox>
                      </div>
                      <div class="form-cell">
                        <mat-checkbox [checked]="true">{{ p.highValueLabel }}</mat-checkbox>
                      </div>
                    </div>
                  </div>
                </mat-step>
                <mat-step label="Exteriöra avvikelser">
                  <div class="form-table">
                    <div *ngFor="let d of conformationDeviations" class="form-row">
                      <div class="form-cell"><label>{{ d }}</label></div>
                      <div class="form-cell">
                        <mat-checkbox [checked]="true">Nej</mat-checkbox>
                      </div>
                      <div class="form-cell">
                        <mat-checkbox [checked]="true">Ja</mat-checkbox>
                      </div>
                    </div>
                  </div>
                </mat-step>
                <mat-step label="Veterinära detaljer">
                  Skilj på has och bakknä
                  Osteochondros okej
                </mat-step>
        -->
      </mat-vertical-stepper>
    </form>

  </mat-sidenav>
  <mat-sidenav-content>

    <div class="stallion-result-area" *ngIf="loading" [style.minHeight]="tableHeight">
      <div class="result-caption">
        <mat-spinner [diameter]="32" [strokeWidth]="2"></mat-spinner>
      </div>
    </div>

    <div class="stallion-result-area" *ngIf="!loading">
      <div class="stallion-table-controls">
        <button *ngIf="!sidenav.opened" mat-button (click)="sidenav.toggle()">
          <mat-icon>chevron_left</mat-icon>
          Öppna formulär
        </button>

        <button *ngIf="sidenav.opened" mat-button (click)="sidenav.toggle()">
          <mat-icon>chevron_right</mat-icon>
          Stäng formulär
        </button>

        <caption class="result-caption">Hittade {{ stallions.length }} hingstar</caption>

        <button mat-button type="button" (click)="onSelectColumns()">
          <mat-icon>view_column</mat-icon>
          Välj kolumner
        </button>
      </div>

      <div *ngIf="stallions">


        <!--<mat-paginator [length]="stallions.length"-->
                       <!--[pageSize]="pageSize"-->
                       <!--[hidePageSize]="true"-->
                       <!--(page)="onPage($event)">-->
        <!--</mat-paginator>-->

        <app-breeders-guide-table
          [columns]="columns"
          [height]="tableHeight"
          [mareId]="formGroup.value.mare?.id"
          [stallions]="stallions"
          [pageSize]="pageSize"
          (sortChange)="sortStallions($event)">

        </app-breeders-guide-table>

      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>

import {Component} from '@angular/core';
import {AbstractHorseFieldComponent} from '../abstract-horse-field.component';
import {Horse} from '../../model/horse';
import {HorseService} from '../horse.service';

@Component({
    selector: 'app-horse-search',
    templateUrl: './horse-search.component.html',
    styleUrls: ['./horse-search.component.css']
})
export class HorseSearchComponent extends AbstractHorseFieldComponent {

    constructor(protected horseService: HorseService) {
        super(horseService);
    }

    horseSelected(horse: Horse) {
        this.selected.emit(horse);
    }

    abortSearch() {
        if (!this.autocomplete.isOpen) {
            this.selected.emit(null);
        }
    }
}

import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-copyright-dialog',
  templateUrl: './copyright-dialog.component.html',
  styleUrls: ['./copyright-dialog.component.css']
})
export class CopyrightDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CopyrightDialogComponent>) { }

  ngOnInit() {
  }

  onAccept() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close(false);
  }


}

<div class="btb-header">
    <div class="mat-display-3">Black Type Breeders</div>
    <div class="mat-display-1"> presenterar sina ettåringar 2020</div>
</div>
<div class="horse-container">
    <mat-card class="horse-card">
        <mat-card-header>
            <mat-card-title>Handsome Devil</mat-card-title>
            <mat-card-subtitle>hingst e. Django Riff – Goodlookngirl 13,3a 154.088 dollar 26 7-6-2</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>
                Modern var flerfaldig stakesvinnare i USA och har tidigare lämnat bland andra
                <span class="horse-emph">Escrow Blue Chip</span>
                11,7a 185.070 dollar och <span class="horse-emph">Only Eyes Broline</span> 13,0a 672.641 kr. Hon är
                själv från en fantastisk
                syskonskara som även innehåller Hambletonian-vinnaren <span class="horse-emph">Vivid Photo</span> 08,6a
                3.273.387 dollar (<span class="horse-emph">S.J.'s Photo</span>s
                vinstrikaste avkomma,
                Hambletonian-tvåan <span class="horse-emph">Devious Man</span> 09,8a 1.338.677 dollar (
                <span class="horse-emph">Credit Winner</span>s vinstikaste avkomma),
                <span class="horse-emph">Whitehouse Express</span> 10,5a 2.128.471 kr, <span class="horse-emph">Celebrity Shark</span>
                12,6a 139.528 dollar och <span class="horse-emph">Broken Record</span> 12,7a
                135.988 dollar.
            </p>
            <p>
                Fadern <span class="horse-emph">Django Riff</span> 12,1a 10.317.806 kr var vinstrikaste hingst i sin
                franska kull både som 2-, 3- och 4-
                åring och hans första avkommor har rönt stort intresse på auktionerna.
            </p>
            <p>
                Morfar <span class="horse-emph">Giant Hit</span> 10,8a
                646.650 dollar har varit en bra hingst i New York och Delaware. Han har en enda svenskfödd
                avkomma och det är Merrie Annabelle-femman <span class="horse-emph">U.S.A.Hornline</span> 12,7a 976.769
                kr (mor till <span class="horse-emph">Electric
                Light</span> 10,3a 1.562.159 kr och <span class="horse-emph">Dynamite Light</span> 11,5a 630.000 kr).
            </p>
            <p>Inavelskoefficient: 3,3 %</p>
        </mat-card-content>
        <mat-card-actions>
            <a href="http://blodbanken.nu/servlet/GetBBData?trotter=17824311" mat-button>Stamtavla</a>
        </mat-card-actions>
    </mat-card>

    <mat-card class="horse-card">
        <mat-card-header>
            <mat-card-title>Maverick Meerkat</mat-card-title>
            <mat-card-subtitle>hingst e. Maharajah – Anastacia Sund ost. e. Scarlet Knight</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>
                Det här är en halvbror till framgångsrika 3-åringen
                <span class="horse-emph">Quantal Quetzal</span> 14,6a 365.000 kr,
                samt till V75-vinnaren <span class="horse-emph">Rocky Reach</span> 12,7a 596.850 kr och högkapabla
                <span class="horse-emph">Breezy Badger</span> 14,3a 281.488 kr
            </p>
            <p>
                Mormor <span class="horse-emph">Kanadia</span> 14,6a 1.149.345 kr
                tillhörde toppen av sin franska kull som 3-åring och har lämnat franske kulltoppen och avelshingsten
                <span class="horse-emph">Canadien d’Am</span>
                12,1 582.010 EUR, de svenska rekordhållerskorna <span class="horse-emph">Geisha Sund</span> 11,6a
                2.965.058 kr och <span class="horse-emph">Bula Bula Am</span>
                10,4a 1.122.574 kr samt <span class="horse-emph">U.P.W.Sund</span> 12.0a 827.550 kr och
                <span class="horse-emph">Europa d’Am</span> 13,2 54.900 EUR.
            </p>
            <p>
                Mormorsfar är den franske hingsten <span class="horse-emph">Canada</span> som är känd som far till
                superstona <span class="horse-emph">Nouba du Saptel</span>
                10,0a 1.713.030 EUR (tvåa i Prix d’Amerique och Prix de France) och <span class="horse-emph">Nouba Turgot</span>
                11,3a 1.033.935 EUR (vinnare av Prix des Elites och trea i Prix de Paris).
            </p>
            <p>Maverick Meerkat är röntgad avseende lösa benbitar utan anmärkning.</p>
            <p>Inavelskoefficient: 6,4 %</p>
        </mat-card-content>
        <mat-card-actions>
            <a href="http://blodbanken.nu/servlet/GetBBData?trotter=17823574" mat-button>Stamtavla</a>
        </mat-card-actions>
    </mat-card>

    <mat-card class="horse-card">
        <mat-card-header>
            <mat-card-title>Sparky's Dream</mat-card-title>
            <mat-card-subtitle>hingst e. Maharajah – Peignoir 13,0v 572.700 kr 32 5-7-4</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>
                Modern Peignoir var V75-vinnare och svensk rekordhållare. Hon är resultatet av en parning av en
                travare och en passgångare, en typ av korsning som blivit alltmer omtalad (se bland annat
                toppfyraåringen
                <span class="horse-emph">Don Fanucci Zet</span> 09,6a 2.813.000 kr och framgångarna för passgångarstammade
                hingstar som <span class="horse-emph">Googoo Gaagaa</span> och
                <span class="horse-emph">Jocose</span>).
            </p>
            <p>
                Peignoirs mor,
                mormor och mormorsmor tävlade alla framgångsrikt som passgångare. Mormorsmor är legendariska
                <span class="horse-emph">Glad Rags</span> p14.0a 227.928 dollar, känd som sin tids bästa passgångarsto
                och mor till framgångsrika
                travarna <span class="horse-emph">Zoot Suit</span>, <span class="horse-emph">Straight Jacket</span> och
                <span
                        class="horse-emph">Sea Leaf</span>.
            </p>
            <p>
                Morfar är <span class="horse-emph">Fast Photo</span> som stora framgångar i den rollen. Bland endast ca
                150 dotteravkommor i startbar ålder
                är han morfar till Gulddivisionsvinnarna <span
                    class="horse-emph">Baron Gift</span> 10,1a 2.611.379 kr (e. <span
                    class="horse-emph">Gift Kronos</span>) och <span
                    class="horse-emph">Stepping
                Spaceboy</span> 10,5a 2.542.900 kr (e. <span
                    class="horse-emph">Dream Vacation</span>), sjufaldiga V75/STL-vinnaren <span
                    class="horse-emph">Ural</span> 10,7a 1.631.700
                kr (e. <span class="horse-emph">Jaded</span>), Guldstovinnaren och E3-tvåan <span class="horse-emph">White Lightning</span>
                11,5a 1.432.707 kr (e. <span
                    class="horse-emph">Maharajah</span>), V75-
                vinnaren <span class="horse-emph">Tellmeastory</span> 11,8a 1.659.168 kr (e. <span class="horse-emph">Zola Boko</span>)
                och Sprintermästarfinalisten <span
                    class="horse-emph">Adde S.H.</span> 11,6a 608.200 kr (e. <span class="horse-emph">Nu Pagadi</span>).
            </p>
            <p>Sparky's Dream är röntgad avseende lösa benbitar utan anmärkning. Han säljs som
                <a href="https://yearlingsale.se/auktioner/wenngarn-yearling-sale-2020/sparkys-dream">nummer 124</a>
                på Wenngarn Yearling Sale som går av stapeln på Solvalla 18 augusti.</p>
            <p><b>Uppdatering:</b> Peignoirs tvååring Dandruff kvalade in på 18,6/2140v på Jägersro 2020-06-16!</p>
            <p>Inavelskoefficient: 7,2 %</p>
        </mat-card-content>
        <mat-card-actions>
            <a href="http://blodbanken.nu/servlet/GetBBData?trotter=17823945" mat-button>Stamtavla</a>
        </mat-card-actions>
    </mat-card>

    <mat-card class="horse-card">
        <mat-card-header>
            <mat-card-title>Wittgenstein</mat-card-title>
            <mat-card-subtitle>hingst e. Brillantissime – Quantum Victoria 14,6a 186.500 kr 12 4-1-0
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>
                Modern var en stor talang som vann fyra av sina fem första starter. Hon gick 16,4/2140v i sin andra
                start och 15,2/2140v i sin fjärde start. I aveln har hon lämnat dubbla V75-vinnaren <span
                    class="horse-emph">Quantum Estelle</span>
                13,1a 539.587 kr och <span class="horse-emph">Klammer</span> 14,3a 191.200 kr.
            </p>
            <p>
                Mormor <span class="horse-emph">Blitzy Sund</span> var sin årgångs ledande sto och är på god väg att
                grunda en stark stolinje.
                Quantum Victoria har flera på banan mindre meriterade halvsystrar som blivit fina fölston och
                producerat hästar som <span class="horse-emph">Chanson d’Inverne</span> 12,3a 2.353.507 kr (mor till
                <span class="horse-emph">Attack Diablo</span> 10,6a 4.416.506
                kr), <span class="horse-emph">Sophias Blush</span> 12,0a 1.305.808 kr,
                <span class="horse-emph">Macho Look</span> 11,8a 1.196.974 kr,
                <span class="horse-emph">Looking Flashy</span> 12,3a 946.525
                kr (mor till <span class="horse-emph">Flash Håleryd</span> 09,7a 1.380.010 kr),
                <span class="horse-emph">Real Showman</span> 11,3a 760.000 kr,
                <span class="horse-emph">Barbie Look</span> 12,5a
                519.972 kr och många fler.
            </p>
            <p>
                Fadern <span class="horse-emph">Brillantissime</span> var kulletta som 2-åring och tidig 3-åring och har
                inlett väldigt starkt i aveln.
                Bland franska treåringar toppar han statistiken sett till intjänade pengar (både totalt och i snitt per
                avkomma). Bland fyraåringarna är han tvåa bakom sin far <span class="horse-emph">Ready Cash</span>.</p>
            <p>Inavelskoefficient: 4,0 %</p>
        </mat-card-content>
        <mat-card-actions>
            <a href="http://blodbanken.nu/servlet/GetBBData?trotter=17823571" mat-button>Stamtavla</a>
        </mat-card-actions>
    </mat-card>
</div>

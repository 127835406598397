import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {tap} from 'rxjs/operators';
import {SearchDataSource} from '../advanced-search/search-datasource';
import {of, Subscription} from 'rxjs';

@Component({
    selector: 'app-production-record',
    templateUrl: './production-record.component.html',
    styleUrls: ['./production-record.component.css']
})
export class ProductionRecordComponent implements OnDestroy, OnInit, AfterViewInit {
    // TODO: dela in i två typer av datasources: en för sök/hingstars avk (server side sorting mm)
    // och en för stons avk (client side sorting samt expansion)
    @Input() dataSource: SearchDataSource;
    columnsToDisplay = ['year', 'name', 'gender', 'blup', 'time', 'earnings', 'starts', 'placeDesc'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    resultCount = of(0);

    private subscription: Subscription = new Subscription();

    constructor() {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.resultCount = this.dataSource.resultCount();
    }

    ngAfterViewInit() {

        // reset the paginator after sorting
        // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.subscription.add(this.sort.sortChange
            .pipe(
                tap(sort => {
                    this.dataSource.reorder({
                        orderBy: sort.active,
                        asc: sort.direction === 'asc'
                    });
                })
            )
            .subscribe());

        this.subscription.add(this.paginator.page
            .pipe(
                tap(pageEvent => {
                    this.dataSource.page(pageEvent.pageIndex * 25);
                })
            ).subscribe());
    }

}

import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from "@angular/core";
@Injectable()
export class Paginator extends MatPaginatorIntl {

  itemsPerPageLabel = 'Rader per sida:';

  nextPageLabel = 'Nästa sida';
  previousPageLabel = 'Föregående sida';

  getRangeLabel =
    (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `Visar 0 av ${length} hästar`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `Visar ${startIndex + 1} - ${endIndex} av ${length} hästar`;
    }
}




<div class="stallion-table-container" [style.height]="height">
  <table mat-table matSort matSortDisableClear="true" [dataSource]="stallions" class="stallion-table"
         (matSortChange)="emitSort($event)">

    <ng-container *ngFor="let col of columns trackBy colId" [matColumnDef]="col.identifier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="col.css" [matTooltip]="col.description">
        {{col.heading}}
      </th>
      <td mat-cell *matCellDef="let row" [ngClass]="col.css">
        <a *ngIf="col.identifier === 'foalLink'" [href]="generateFoalLink(row.id)" target="_blank">
          <mat-icon
            [inline]="true">open_in_new
          </mat-icon>
        </a>
        <div *ngIf="col.identifier === 'name'" class="mat-body-strong stallion-name-container">
          {{ col.extractorFunction(row) }}
          <a [href]="generateStLink(row.idSt)" target="_blank">
            <mat-icon
              [inline]="true">open_in_new
            </mat-icon>
          </a>
        </div>
        <span *ngIf="col.identifier !== 'foalLink' && col.identifier !== 'name'">
              {{ col.extractorFunction(row) }}
            </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns();">
    </tr>

  </table>
</div>

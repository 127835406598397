<div class="slider mat-typography">
  <label *ngIf="label" class="slider-label"
         [ngClass]="{'active': slider.currentFocusElement, 'inactive': !slider.currentFocusElement }">
    {{label}}
  </label>
  <ngx-slider #slider
    [(value)]="range.min"
    [(highValue)]="range.max"
    [options]="options"
    (userChangeEnd)="changed()">
  </ngx-slider>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Pedigree} from './pedigree';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../config.service';

type AncestorType = 'f' | 'm';

@Injectable()
export class PedigreeService {

  constructor(private config: ConfigService, private httpClient: HttpClient) {
    }

  private static otherThan(position: AncestorType): AncestorType {
    return position === 'f' ? 'm' : 'f';
  }

    getMainHorsePedigree(id: number, size: number): Observable<Pedigree> {
        return this.getPedigree({h: id, size: size});
    }

    getTestMatingPedigree(sireId: number, damId: number, size: number): Observable<Pedigree> {
        return this.getPedigree({f: sireId, m: damId, size: size});
    }

    getPedigree(params: any): Observable<Pedigree> {
        return this.httpClient.get<Pedigree>(this.config.getBackEndHost() + '/pedigree', {params: params});
    }

    // below should probably go into another service

    createTestMatingParams(originalPedigree: Pedigree, replacementPosition: string, replacementHorseId: number) {
        const map: Map<string, number> = new Map<string, number>();
        map.set(replacementPosition, replacementHorseId);
        const positions = [...replacementPosition]
            .map((_: string, index: number) => this.replaceLast(replacementPosition.substring(0, index + 1)));
        originalPedigree.fields
            .filter(field => positions.includes(field.position))
            .forEach(field => map.set(field.position, field.horse.id));
        return Object.fromEntries(map);
    }

    private replaceLast(pos: string): string {
        return pos.substring(0, pos.length - 1) + PedigreeService.otherThan(pos.substring(pos.length-1) as AncestorType);
    }

}

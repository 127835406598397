<div class = "production-record">
    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> År</th>
            <td mat-cell *matCellDef="let horse"> {{ horse.birthYear }} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell class="name-col" *matHeaderCellDef mat-sort-header> Namn</th>
            <td mat-cell class="name-col" *matCellDef="let horse">
                <a href="{{ 'http://www.blodbanken.nu/servlet/GetBBData?trotter=' + horse.id  }} " target="_blank">{{ horse.name }}
                <span *ngIf="horse.nationality">({{horse.nationality}})</span></a>
            </td>
        </ng-container>
        <ng-container matColumnDef="gender">
            <th mat-header-cell class="gender-col" *matHeaderCellDef> Kön</th>
            <td mat-cell class="gender-col" *matCellDef="let horse"> {{ horse.gender }} </td>
        </ng-container>
        <ng-container matColumnDef="blup">
            <th mat-header-cell class="blup-col" *matHeaderCellDef mat-sort-header> Blup</th>
            <td mat-cell class="blup-col" *matCellDef="let horse"> {{ horse.blup }} </td>
        </ng-container>
        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rekord</th>
            <td mat-cell *matCellDef="let horse"> {{ horse.timeDesc }} </td>
        </ng-container>
        <ng-container matColumnDef="earnings">
            <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header> Prissumma</th>
            <td mat-cell class="right-align" *matCellDef="let horse"> {{ horse.earningsDesc }} </td>
        </ng-container>
        <ng-container matColumnDef="starts">
            <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header> Starter</th>
            <td mat-cell class="right-align" *matCellDef="let horse"> {{ horse.starts }} </td>
        </ng-container>
        <ng-container matColumnDef="placeDesc">
            <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header> Placeringar</th>
            <td mat-cell class="right-align" *matCellDef="let horse"> {{ horse.placeDesc }} </td>
        </ng-container>
    </table>
    <mat-paginator [length]="resultCount | async" [pageSize]="25"></mat-paginator>
</div>

<mat-toolbar>
    <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <a class="top-link" routerLink="/">

            <img src="../assets/img/Blodbanken_s_swe.png" height="16" width="156" alt="Blodbanken logo" /></a>
        <span class="spacer"></span>
        <a class="top-link mat-body-2" routerLink="/btb"></a>
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" closed>
        <mat-nav-list>
            <a href="servlet/GetBBData">
                <mat-list-item>Härstamning
                    <mat-icon>open_in_new</mat-icon>
                </mat-list-item>
            </a>
            <a href="servlet/GetBBData?type=productionoverview">
                <mat-list-item>Förärvning
                    <mat-icon>open_in_new</mat-icon>
                </mat-list-item>
            </a>
            <a href="servlet/GetBBData?type=stats">
                <mat-list-item>Hingststatistik
                    <mat-icon>open_in_new</mat-icon>
                </mat-list-item>
            </a>
            <a mat-list-item routerLink="/duels">Hingstduellen</a>
            <a mat-list-item routerLink="/breeders-guide">Avelsvägledaren</a>
            <a mat-list-item routerLink="/advanced-search">Avancerad sökning</a>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="main-container">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

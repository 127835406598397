import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Horse} from '../model/horse';
import {Observable} from 'rxjs';
import {SearchFilter} from './searchFilter';
import {HttpParams} from '@angular/common/http';
import {ConfigService} from '../config.service';


@Injectable({
  providedIn: 'root'
})
export class HorseService {

  constructor(private config: ConfigService, private httpClient: HttpClient) {
  }

  searchHorse(searchString: string, filter: SearchFilter): Observable<Horse[]> {
    if (!filter) {
      filter = <SearchFilter>{};
    }
    filter.name = searchString + '%';
    return this.httpClient.get<Horse[]>(this.config.getBackEndHost() + '/horses',
      { params: this.filterToHttpParams(filter) });
  }

  private filterToHttpParams(filter: SearchFilter): HttpParams {
    let params: HttpParams = new HttpParams();
    Object.keys(filter).forEach(key => {
      params = params.append(key, filter[key]);
    });
    return params;
  }

}

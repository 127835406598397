<form (submit)="onSubmit()">
    <h2 mat-dialog-title>{{ this.data.crit.label }}</h2>
    <mat-dialog-content>
        <ng-template appSearchCriterionForm></ng-template>
        <div class="validation-feedbank-panel" *ngIf="validationError">
            <mat-icon color="warn" inline>error</mat-icon> {{ validationError }}
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button type="submit" mat-raised-button color="primary">{{ okButtonLabel }}</button>
        <button type="button" mat-button (click)="onCancel()">Avbryt</button>
    </mat-dialog-actions>
</form>

<mat-sidenav-container>
    <mat-sidenav opened="true" mode="side">
        <mat-list>
            <mat-list-item *ngFor="let crit of criteriaTypes">
                <button mat-button (click)="addCriterion(crit)"
                        [disabled]="!crit.multipleAllowed && !!criterionSelected(crit)">{{ crit.label }}</button>
            </mat-list-item>
        </mat-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="mat-title">Sökkriterier</div>
        <div *ngIf="criteria.length === 0" class="mat-hint">Lägg till sökkriterier från listan till höger</div>
        <mat-chip-list>
            <mat-chip *ngFor="let crit of criteria; index as i" removable="true" (removed)="removeCriterion(i)">
                <span class="max-shrink">{{ crit.criterionType.label }}
                    : {{ crit.criterionType.render(crit.model, crit.extra) }}</span>
                <mat-icon class="max-shrink" inline="true" matChipTrailingIcon (click)="editCriterion(i)">edit
                </mat-icon>
                <!--<span class="spacer"> </span>-->
                <mat-icon class="max-shrink" matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>

        <button mat-fab class="search-button" (click)="onSearch()" [disabled]="state === 'LOADING'">
            <mat-spinner diameter="24" strokeWidth="3" *ngIf="state === 'LOADING'"></mat-spinner>
            <mat-icon *ngIf="state !== 'LOADING'">search</mat-icon>
        </button>

        <div class="mat-title result-header">Sökresultat</div>

        <table class="stat-table" *ngIf="datasource.statSe$ | async as stats">
            <tr><td>Antal:</td><td>{{ stats.count }} svenskfödda</td></tr>
            <tr><td>Födda:</td><td>{{ stats.minYear }}<span *ngIf="stats.minYear < stats.maxYear"> till {{ stats.maxYear }}</span></td></tr>
            <tr><td>Dev:</td><td>{{ stats.dev }}</td></tr>
        </table>

        <app-production-record [dataSource]="datasource"></app-production-record>
        <div *ngIf="state === 'TIMEOUT'" class="mat-warn">Vi är ledsna, men sökningen tog för lång tid.
            Antingen var sökningen i sig väldigt stor eller så är servern hårt belastad.
            Om du söker på anförälder, prova att minska djupet eller snäva av platser i stamtavlan. </div>

    </mat-sidenav-content>
</mat-sidenav-container>


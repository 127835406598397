import {Component, Input, EventEmitter, Output} from "@angular/core";
@Component({
  selector: 'app-help-section',
  templateUrl: 'help-section.component.html',
  styleUrls: ['help-section.component.css']
})
export class HelpSectionComponent {

  @Input() first: boolean;
  @Input() label: string;
  @Input() last: boolean;
  @Output() close = new EventEmitter<any>();

  constructor() {}


  onClose() {
    this.close.emit();
  }
}

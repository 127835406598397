<table border="1">
  <ng-container *ngFor="let r of recordEntries">
    <tr>
      <td rowspan="2">{{ r.dam.name }}</td>
      <td>{{ r.foal1.name }}</td>
      <td>{{ r.foal1.timeDesc }}</td>
      <td>{{ r.foal1.earningsDesc }}</td>
      <td>{{ r.foal1.starts }}</td>
      <td>{{ r.foal1.placeDesc }}</td>
      <td>{{ r.foal1.brix | number:'1.1-1' }}</td>
    </tr>
    <tr>
      <td>{{ r.foal2.name }}</td>
      <td>{{ r.foal2.timeDesc }}</td>
      <td>{{ r.foal2.earningsDesc }}</td>
      <td>{{ r.foal2.starts }}</td>
      <td>{{ r.foal2.placeDesc }}</td>
      <td>{{ r.foal2.brix | number:'1.1-1' }}</td>
    </tr>
  </ng-container>
</table>

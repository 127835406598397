import {Component, OnInit} from '@angular/core';
import {SearchFormComponent} from '../search-form-component';
import {Horse} from '../../model/horse';

@Component({
    selector: 'app-inbreeding-criterion',
    templateUrl: './inbreeding-criterion.component.html',
    styleUrls: ['./inbreeding-criterion.component.css']
})
export class InbreedingCriterionComponent implements SearchFormComponent, OnInit {
    readonly patterns = [
        'ALL_PATTERNS',
        '1+2',
        '1+3',
        '1+4',
        '1+5',
        '1+6',
        '2+2',
        '2+3',
        '2+4',
        '2+5',
        '2+6',
        '3+3',
        '3+4',
        '3+5',
        '3+6',
        '4+4',
        '4+5',
        '4+6',
        '5+5',
        '5+6',
        '6+6'
    ];
    selectedHorse: Horse;
    selectedPattern = 0;

    constructor() {
    }

    ngOnInit() {
    }

    initModel(model: any, extra?: any): void {
        this.selectedHorse = extra.horse;
        this.selectedPattern = model.pattern;
    }

    retrieveExtra(): any {
        return {
            horse: this.selectedHorse
        };
    }

    retrieveModel(): any {
        return {
            id: this.selectedHorse.id,
            isMale: !(this.selectedHorse.gender === 's'),
            pattern: this.selectedPattern
        };
    }

    validationError(): string {
        if (!this.selectedHorse) {
            return 'Vänligen välj en anförälder.';
        }
        if (!this.selectedPattern) {
            return 'Vänligen välj mönster.';
        }
        return '';
    }

}

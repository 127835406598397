import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HorseSearchComponent} from '../../horse-field/horse-search/horse-search.component';
import {PedigreeField} from '../pedigreeField';
import {Horse} from '../../model/horse';
import {HorseDisplaySize} from '../../shared/horse-display/horse-display.component';

@Component({
    selector: 'app-pedigree-field',
    templateUrl: './pedigree-field.component.html',
    styleUrls: ['./pedigree-field.component.css']
})
export class PedigreeFieldComponent implements OnInit {

    @Input() pedigreeField: PedigreeField;
    @Input() size: number;
    @Output() replacement = new EventEmitter<PedigreeField>();
    private horseSearch: HorseSearchComponent;

    searchOpen = false;
    horseDisplaySize: HorseDisplaySize;

    @ViewChild('horseSearch') set content(content: HorseSearchComponent) {
        if (content) {
            this.horseSearch = content;
            content.focus();
        }
    }

    ngOnInit(): void {
        this.horseDisplaySize = (this.size > 0 ? 'medium' : 'small');
    }

    onSelectedHorse(ev: Horse) {
        if (ev) {
            this.replacement.emit({
                horse: ev,
                position: this.pedigreeField.position,
                styleNr: this.pedigreeField.styleNr
            });
        } else {
            this.searchOpen = false;
        }
    }

    openSearch() {
        this.searchOpen = true;
    }
}

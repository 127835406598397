import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  selector: 'app-size-selector',
  templateUrl: './size-selector.component.html',
  styleUrls: ['./size-selector.component.css']
})
export class SizeSelectorComponent  {

  @Input() size = 5;
  @Output() change = new EventEmitter<number>();
  @ViewChild('group') group: MatButtonToggleGroup;

  updateSize() {
    this.change.emit(this.group.value);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Duel} from '../model/duel';
import {HttpParams} from '@angular/common/http';
import {DuelRecord} from '../model/duelrecord';
import {ConfigService} from '../config.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DuelService {

  constructor(private config: ConfigService, private httpClient: HttpClient) {

  }

  createDuel(id1: number, id2: number): Observable<Duel> {
    const params = new HttpParams().set('s1', String(id1)).set('s2', String(id2));
    return this.httpClient.get<Duel[]>(this.config.getBackEndHost() + '/duels', { params: params }).pipe(
      map(duels => duels[0])
    );
  }

  findDuelRecord(id1: number, id2: number): Observable<DuelRecord[]> {
    const params = new HttpParams().set('s1', String(id1)).set('s2', String(id2));
    return this.httpClient.get<DuelRecord[]>(this.config.getBackEndHost() + '/duels/record', { params: params });
  }

  findDuels(id: number): Observable<Duel[]> {
    const params = new HttpParams().set('s1', String(id));
    return this.httpClient.get<Duel[]>(this.config.getBackEndHost() + '/duels', { params: params });
  }
}
